// components/Loader.tsx
// "use client";
import React from "react";
import "../style/loader.css";
const Loader = () => {
  return (
    <div className="preloader">
      <div className="ldsEllipsis">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
