import ProtectedRoute from "./components/routes/ProtectedRoute";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SignIn from "./components/signIn/SignIn";
import SignUp from "./components/signUp/SignUp";
import Home from "./components/Home";
import MovieDetail from "./components/detail/MovieDetail";
import "../src/components/style/mainStyle.css";
import "../src/components/style/megaMenu.css";
import "../src/components/style/responsive.css";
import Subscriptions from "./components/subscription/Subscriptions";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ComingSoon from "./components/comingSoon/ComingSoon";
import Profile from "./components/account/Profile";
import MovieWatchDetails from "./components/detail/MovieWatchDetails";
import CateoryDetails from "./components/category/CateoryDetails";
import ContactUs from "./components/account/ContactUs";
import Dashboard from "./components/account/Dashboard";
import PrivacyPolicy from "./components/info/PrivacyPolicy";
import TermsAndConditions from "./components/info/TermsAndConditions";
import RefundPolicy from "./components/info/RefundPolicy";
import AboutUs from "./components/info/AboutUs";
import CancellationPolicy from "./components/info/CancellationPolicy";
import ValidateOTP from "./components/signIn/ValidateOTP";
import PageNotFound from "./components/PageNotFound";
import { GlobalDebug } from "./removeLog";

const App = () => {
  useEffect(() => {
    GlobalDebug(true);
  }, []);

  const isLoggedInLocalStorage = localStorage.getItem("isLoggedIn");
  const [isLoggedIn] = useState(isLoggedInLocalStorage === "TRUE");
  const [isSubscribedUser] = useState(
    localStorage.getItem("isSubscribedUser") != "false"
  );
  const [isVerified] = useState(localStorage.getItem("isVerified") != "false");
  //console.log("isLoggedIn", isLoggedIn);
  const MainLayout = ({ children }) => (
    <>
      <Header />
      <div style={{ paddingTop: "5rem" }}>{children}</div>
      <Footer />
    </>
  );
  const SignupLayout = ({ children }) => <>{children}</>;
  return (
    <>
      <Routes>
        <Route
          path="/signin"
          element={
            <>
              <Header />
              <SignIn />
            </>
          }
        />
        <Route
          path="/validateOTP"
          element={
            <>
              <Header />
              <ValidateOTP />
            </>
          }
        />
        <Route
          path="/signup"
          element={
            <>
              <Header />
              <SignUp />
            </>
          }
        />
        <Route
          path="/home"
          element={
            <MainLayout>
              {" "}
              <Home />{" "}
            </MainLayout>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <MainLayout>
              {" "}
              <Subscriptions />
            </MainLayout>
          }
        />
        <Route
          path="/comingSoon"
          element={
            <MainLayout>
              {" "}
              <ComingSoon />
            </MainLayout>
          }
        />
        <Route
          path="/privacyPolicy"
          element={
            <MainLayout>
              {" "}
              <PrivacyPolicy />
            </MainLayout>
          }
        />
        <Route
          path="/termsAndConditions"
          element={
            <MainLayout>
              {" "}
              <TermsAndConditions />
            </MainLayout>
          }
        />
        <Route
          path="/refundPolicy"
          element={
            <MainLayout>
              {" "}
              <RefundPolicy />
            </MainLayout>
          }
        />
        <Route
          path="/cancellationPolicy"
          element={
            <MainLayout>
              {" "}
              <CancellationPolicy />
            </MainLayout>
          }
        />
        <Route
          path="/aboutUs"
          element={
            <MainLayout>
              {" "}
              <AboutUs />
            </MainLayout>
          }
        />
        <Route
          path="/contactUs"
          element={
            <MainLayout>
              {" "}
              <ContactUs />
            </MainLayout>
          }
        />

        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <MainLayout>
                <Routes>
                  {/* Protected routes */}
                  <Route element={<ProtectedRoute />}>
                    <Route path="/home" element={<Home />} />
                    {/* <Route path="/movie/:id" element={<MovieDetail />} /> */}
                    <Route
                      path="/movie/details/:id"
                      element={<MovieDetail />}
                    />
                    <Route path="/show/details/:id" element={<MovieDetail />} />
                    <Route
                      path="/movie/watch/:id"
                      element={<MovieWatchDetails />}
                    />
                    <Route path="/category/:id" element={<CateoryDetails />} />
                    <Route path="/subscriptions" element={<Subscriptions />} />
                    <Route path="/comingSoon" element={<ComingSoon />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/contactUs" element={<ContactUs />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                  </Route>

                  {/* Default route */}
                  <Route
                    path="/"
                    element={
                      isSubscribedUser && isVerified ? (
                        <Home />
                      ) : isVerified ? (
                        <Subscriptions />
                      ) : (
                        <ValidateOTP />
                      )
                    }
                  />

                  <Route path="/*" element={<PageNotFound />} />
                </Routes>
              </MainLayout>
            ) : (
              <MainLayout>
                <Routes>
                  <Route
                    path="/"
                    element={
                      isLoggedIn ? (
                        isVerified ? (
                          <Subscriptions />
                        ) : (
                          <ValidateOTP />
                        )
                      ) : (
                        <SignIn />
                      )
                    }
                  />
                  <Route path="/*" element={<Navigate to={"/signin"} />} />
                </Routes>
              </MainLayout>
            )
          }
        />
      </Routes>
    </>
  );
};

export default App;
