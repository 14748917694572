import React, { useEffect, useState } from "react";
import "../style/mainStyle.css";

import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import service from "../network/service";
import VideoDetails from "./VideoDetails";
import ShowDetails from "./ShowDetails";
import Loader from "../utils/Loader";

function MovieDetail(props) {
  // console.log("props", props);
  const location = useLocation();
  // console.log("location", location);
  const params = useParams();
  console.log("params", params);
  const { id } = useParams();
  const type = location.state?.type;
  // console.log("type", type);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const currentURL = window.location.href;
  const searchParams = new URLSearchParams(location.search);

  const resumeTime = searchParams.get("watch");
  // Use the URL constructor to parse the URL
  const url = new URL(currentURL);

  // Access the pathname and split it to get the parts
  const pathnameParts = url.pathname.split("/");

  // Find the index of 'show' in the pathname
  const indexOfShow = pathnameParts.indexOf("show");
  //console.log("indexOfShow", indexOfShow);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (type === "SHOW" || indexOfShow == 1) {
          const showData = await service.showDetails(id);
          //console.log("showData", showData);
          setData(showData.data);
        } else if (type === "SEASON") {
          const seasonData = await service.seasonDetails(id);
          setData(seasonData.data);
        } else {
          //console.log("ELSE");
          const videoData = await service.videoDetails(id);
          //console.log("videoData", videoData);
          setData(videoData.data);
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here
        setLoader(false);
      }
    };

    fetchData();
  }, [type, id]);

  return (
    <>
      {loader && <Loader />}
      {!loader && data && (type === "SHOW" || indexOfShow == 1) && (
        <ShowDetails data={data} id={id} />
      )}
      {!loader && data && type !== "SHOW" && indexOfShow !== 1 && (
        <VideoDetails data={data} id={id} resumeTime={resumeTime} />
      )}
    </>
  );
}

export default MovieDetail;
