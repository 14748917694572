import React, { useState } from "react";

const SearchComponent = ({ searchQuery, setSearchQuery }) => {
  return (
    <div className="search-input">
      <input
        type="text"
        placeholder="Search movies..."
        value={searchQuery}
        className="search-container-input"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />
    </div>
  );
};

export default SearchComponent;
