import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@splidejs/splide/css";
import "../style/responsive.css";
import "../style/megaMenu.css";
import "../style/mainStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import style from "../style/customSocialIcon.css"; // Import your CSS module
import { Link } from "react-router-dom";

function Footer() {
  const handleEmailButtonClick = () => {
    const emailAddress = "sedusaott@gmail.com";
    const subject = "Hello";
    const body = "I hope this email finds you well.";

    // Construct the mailto link
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the mailto link in a new tab
    window.open(mailtoLink);
  };
  return (
    <footer>
      <div
        className="footer-area vfx-item-ptb"
        style={{
          bottom: "0",
          width: "-webkit-fill-available",
        }}
      >
        <div className="footer-wrapper">
          <div className="container-fluid">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="footer-bottom">
                  <div className="footer-links">
                    <ul>
                      <li>
                        <Link to="/termsAndConditions" title="Terms Of Use">
                          Terms Of Use
                        </Link>
                      </li>
                      <ul>
                        <li>
                          <Link to="/privacyPolicy" title="Privacy Policy">
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="/refundPolicy" title="Privacy Policy">
                            Refund Policy
                          </Link>
                        </li>
                      </ul>
                    </ul>
                    <ul>
                      <li>
                        <Link to="/aboutUs" title="Privacy Policy">
                          About Us
                        </Link>
                      </li>
                      <li
                        // onClick={handleEmailButtonClick}
                        style={{ cursor: "pointer" }}
                      >
                        <Link to="/contactUs">
                          {/* <Link to="/contactUs" title="Contact Us"> */}
                          Contact Us
                        </Link>
                        {/* </Link> */}
                        <li
                          // onClick={handleEmailButtonClick}
                          style={{ cursor: "pointer" }}
                        >
                          <Link to="/cancellationPolicy">
                            {/* <Link to="/contactUs" title="Contact Us"> */}
                            Cancellation Policy
                          </Link>
                          {/* </Link> */}
                        </li>
                      </li>
                    </ul>
                  </div>
                  <div className="copyright-text">
                    <p>Copyright © 2023 www.sedusa.in All Rights Reserved.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="single-footer">
                  <div className="footer-heading-wrap">
                    <h3 className="footer-heading">Connect with us</h3>
                  </div>
                  <div className="social-links">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=61552949372118&mibextid=kFxxJD"
                          title="facebook"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faFacebook}
                            size="sm"
                            className={style.customSocialIcon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://youtube.com/@Sedusaottchannel?si=qs-BjxaqW8Apqmp-"
                          title="youtube"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faYoutube}
                            size="sm"
                            className={style.customSocialIcon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/sedusaott_?igsh=OGQ5ZDc2ODk2ZA=="
                          title="instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            size="sm"
                            className={style.customSocialIcon}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-2">
                    <div>10/252 FC, Thekkumpadam Road</div>
                    <div>Poovanchira,Pattikkadu</div>
                    <div>Thrissur 680652</div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="single-footer">
                          <div className="footer-heading-wrap">
                            <h3 className="footer-heading">Apps</h3>
                          </div>
                          <div className="download-app-link-item">
                            <a
                              className="google-play-download"
                              href="https://play.google.com/"
                              target="_blank"
                              title="Google Play"
                            >
                              <img
                                src="/images/google-play.png"
                                alt="Google Play Download"
                                title="Google Play Download"
                              />
                            </a>
                            <a
                              className="apple-store-download"
                              href="https://apps.apple.com/"
                              target="_blank"
                              title="Apple Store"
                            >
                              <img
                                src="/images/app-store.png"
                                alt="Apple Store Download"
                                title="Apple Store Download"
                              />
                            </a>
                          </div>
                        </div>
                      </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-top">
        <div className="scroll-icon">
          {" "}
          <i className="fa fa-angle-up"></i>{" "}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
