import React, { useEffect } from "react";
import { useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import Loader from "../utils/Loader";
import service from "../network/service";
import "../style/categoryStyle.css";
function CateoryDetails() {
  const { id } = useParams();
  //console.log("id", id);
  const location = useLocation();
  const categoryName = location?.state?.name;
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    fetchCategory(id);
  }, []);

  function fetchCategory(id) {
    service.categoryDetails(id).then((data) => {
      data.data.map((item) => {
        item.linkTo =
          item.type == "VIDEO"
            ? `/movie/details/${encodeURIComponent(item?.id)}`
            : `/show/details/${encodeURIComponent(item?.id)}`;
        return item;
      });
      setData(data.data);
      setLoader(false);
    });
  }
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="breadcrumb-section bg-xs sedusa-category-breadcrumb">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12">
                  <h2>{categoryName}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="view-all-video-area vfx-item-ptb">
            <div className="container-fluid">
              <div className="row">
                <div className="sesusa-category-details">
                  {data.map((item) => {
                    return (
                      <div className="single-video">
                        <Link
                          to={item.linkTo}
                          title={item?.name || item?.title}
                          state={{ type: item.type }}
                        >
                          <div className="video-img">
                            <span className="video-item-content">
                              {item?.name || item?.title}
                            </span>
                            <img
                              className="sedusa-category-img"
                              src={item.thumbnail_vertical}
                              alt={item?.name || item?.title}
                              title={item?.name || item?.title}
                            />
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CateoryDetails;
