import React, { useState, useEffect } from "react";
import "../style/mainStyle.css";
import "../style/responsive.css";
import { useLocation, useSearchParams } from "react-router-dom";
import VideoPlayer from "../video/VideoPlayer";
import Loader from "../utils/Loader";

function MovieWatchDetails() {
  const location = useLocation();
  const data = location.state.data;
  const [loader, setLoader] = useState(true);
  const searchParams = new URLSearchParams(location.search);

  const resumeTime = searchParams.get("watch");

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  console.log("asdasdasdsadassdsds", data);

  const [tab, setTab] = useState("DESCRIPTION");
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="page-content-area vfx-item-ptb pt-0">
          <div className="container-fluid bg-dark video-player-base">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="video-posts-video">
                  <VideoPlayer
                    thumbnail={data?.thumbnail_horizontal}
                    id={data.id}
                    verticalImage={data?.thumbnail_vertical}
                    name={data?.title}
                    resumeSecond={resumeTime}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {/* <!-- Start Video Post --> */}
                <div className="video-post-wrapper">
                  <div className="row mt-30">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="video-posts-data mt-0 mb-0">
                        <div className="video-post-info">
                          <h2>{data.name}</h2>
                          <div className="video-post-date">
                            {/* <span className="video-posts-author">
                              <i className="fa fa-eye"></i>35 Views
                            </span> */}

                            <span className="video-posts-author">
                              <i className="fa fa-calendar-alt"></i>Sep 16 2022
                            </span>
                          </div>
                          <ul className="actor-video-link">
                            {data.categories?.map((item) => {
                              return (
                                <li>
                                  <a
                                    href="https://demoo.sedusa.in/movies?genre_id=2"
                                    title="Action"
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vfx-tabs-item mt-30">
                    <input
                      checked={tab === "DESCRIPTION"}
                      id="tab1"
                      type="radio"
                      name="pct"
                      readOnly
                    />
                    {Object.keys(data.castNcrew).map((role, index) => (
                      <input
                        key={index}
                        checked={tab === role.toUpperCase()}
                        id={`tab${index + 2}`}
                        type="radio"
                        name="pct"
                        readOnly
                      />
                    ))}
                    <nav>
                      <ul>
                        <li
                          className="tab1"
                          onClick={() => {
                            setTab("DESCRIPTION");
                          }}
                        >
                          <label htmlFor="tab1">Description</label>
                        </li>

                        {Object.keys(data.castNcrew).length !== 0 &&
                          Object.keys(data.castNcrew).map((role, index) => (
                            <li
                              key={index}
                              className={`tab${index + 2}`}
                              onClick={() => {
                                setTab(role.toUpperCase());
                              }}
                            >
                              <label htmlFor={`tab${index + 2}`}>{role}</label>
                            </li>
                          ))}
                      </ul>
                    </nav>
                    <section className="tabs_item_block">
                      {tab === "DESCRIPTION" && (
                        <div className="tab1">
                          <div className="description-detail-item">
                            {data?.description}
                          </div>
                        </div>
                      )}
                      {Object.keys(data.castNcrew).map((role, index) => (
                        <div key={index} className={`tab${index + 2}`}>
                          <div className="row">
                            {data.castNcrew[role].map((item, subIndex) => (
                              <div
                                key={subIndex}
                                className="col-lg-2 col-md-3 col-sm-4 col-xs-12 col-6"
                              >
                                <div className="actors-member-item">
                                  <img
                                    src={item.imageLink}
                                    alt={item.name}
                                    title={item.name}
                                  />
                                  <span>{item.name}</span>
                                  <a
                                    href={item.imdbLink}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    IMDB Link
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MovieWatchDetails;
