import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import service from "../network/service";
import Loader from "../utils/Loader";

function Dashboard() {
  let user = JSON.parse(localStorage.getItem("user"));

  const [data, setData] = useState(user);
  const [loader, setLoader] = useState(true);
  const [currentActiveSubscription, setCurrentActiveSubscription] = useState({
    name: "",
    amount: "",
    endDate: "",
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (data?.userSubscriptions) {
      const actSub = data?.userSubscriptions?.find(
        (sub) => sub?.currentStatus == "ACTIVE"
      );
      setCurrentActiveSubscription({
        name: actSub?.subscriptionPlan?.name,
        amount: actSub?.subscriptionPlan?.price,
        endDate: new Date(actSub?.endDate),
      });
      setLoader(false);
    }
  }, [data]);

  const fetchProfile = () => {
    service.fetchProfile().then((data) => {
      setData(data.data);
    });
  };

  return (
    <>
      {
        <>
          <div
            class="breadcrumb-section bg-xs"
            // style="background-image: url('/images/breadcrum-bg.jpg')"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-12">
                  <h2>Dashboard</h2>
                </div>
              </div>
            </div>
          </div>

          <div class="vfx-item-ptb vfx-item-info">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="profile-section">
                    <div class="row">
                      <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <div class="img-profile">
                          <img
                            src="/images/user-avatar.png"
                            class="img-rounded"
                            alt="profile pic"
                            title="profile pic"
                          />
                        </div>
                        <div class="profile_title_item">
                          <h5>{data?.firstName}</h5>
                          <p>{data?.email}</p>
                          {data?.userCouponCode && (
                            <p>Code: {data?.userCouponCode}</p>
                          )}
                          <Link
                            to="/profile"
                            class="vfx-item-btn-danger text-uppercase"
                          >
                            <i class="fa fa-edit"></i>Edit
                          </Link>
                        </div>
                      </div>
                      <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="member-ship-option">
                              <h5 class="color-up">My Subscription</h5>
                              {loader ? (
                                <Loader />
                              ) : (
                                <div>
                                  <div>
                                    <h4>{currentActiveSubscription?.name}</h4>
                                  </div>
                                  <p>
                                    Amount: {currentActiveSubscription?.amount}
                                  </p>
                                  <p>
                                    End Date:{" "}
                                    {currentActiveSubscription?.endDate &&
                                      new Date(
                                        currentActiveSubscription?.endDate?.setDate(
                                          currentActiveSubscription?.endDate?.getDate() -
                                            1
                                        )
                                      ).toLocaleDateString()}
                                  </p>
                                </div>
                              )}

                              <div class="mt-3">
                                <Link
                                  to="/subscriptions"
                                  class="vfx-item-btn-danger text-uppercase"
                                >
                                  Select Plan
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="member-ship-option">
                      <h5 class="color-up">Last Invoice</h5>
                      <span class="premuim-memplan-bold-text">
                        <strong>Date:</strong>
                      </span>
                      <span class="premuim-memplan-bold-text">
                        <strong>Plan:</strong>
                      </span>
                      <span class="premuim-memplan-bold-text">
                        <strong>Amount:</strong>
                      </span>
                    </div>
                  </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="table-wrapper">
            <div class="vfx-item-section">
              <h3>User History</h3>
            </div>

            <table class="fl-table">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Amount</th>
                  <th>Payment Gateway</th>
                  <th>Payment ID</th>
                  <th>Payment Date</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>

          <div class="col-xs-12"></div>
        </div> */}
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Dashboard;
