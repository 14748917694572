import Loader from "./utils/Loader";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@splidejs/splide/css";
import "./style/responsive.css";
import "./style/megaMenu.css";
import "./style/mainStyle.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "./style/UpcomingSplider.css";
import "./style/search.css";
import { Link } from "react-router-dom";
import Slider from "./reusable/Slider";
import CategorySlider from "./reusable/CategorySlider";
import CategorySliderWithTitle from "./reusable/CategorySliderWithTitle";
import service from "./network/service";

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    fetchHomeData();
    fetchBanners();
  }, []);

  useEffect(() => {
    const setLoaderState = data.length && banners.length;
    console.log("setLoaderState", setLoaderState);
    if (setLoaderState) {
      setLoading(false);
    }
  }, [data, banners]);

  const fetchHomeData = () => {
    service
      .home()
      .then((data) => {
        let mappedCategoryData = [];
        data.data.forEach((category) => {
          //console.log(category?.categoryName);
          if (category?.categoryName !== "BANNER") {
            let allShows = true;

            category.items.forEach((item) => {
              // Modify each item in place and add new properties
              item.title = item.itemName;
              item.imageSrc = item.horizondalImage;
              item.linkTo =
                item.itemType == "VIDEO"
                  ? `/movie/details/${encodeURIComponent(item?.itemId)}`
                  : `/show/details/${encodeURIComponent(item?.itemId)}`;
              item.categoryName = category.categoryName;
              item.categoryId = category.categoryId;

              item.resumeTime = item?.resumeTimeInSeconds || 0;

              // Check if itemType is 'VIDEO'
              if (item.itemType === "VIDEO") {
                allShows = false;
              }
            });

            // Add tileType property based on allShows
            category.tileType = allShows ? "horizontal" : "vertical";
            if (!allShows) {
              category.items.forEach((item) => {
                item.imageSrc = item.verticalImage;
              });
            }

            mappedCategoryData.push(category);
          }
        });
        //console.log(mappedCategoryData);
        setData(mappedCategoryData);
      })
      .catch((error) => {
        //handle error
      });
  };

  const fetchBanners = () => {
    service.banners().then((data) => {
      data.data.map((banner) => {
        banner.imageSrc = banner.horizontalBannerUrl;
        banner.title = banner.title;
        // banner.linkTo=`/movie/${banner?.itemId}`;
        banner.linkTo =
          banner.type == "SHOW"
            ? `/show/details/${encodeURIComponent(banner?.showId)}`
            : `/movie/details/${encodeURIComponent(banner?.videoId)}`;
      });
      setBanners(data.data);
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="slider-area">
              <div className="container-fluid">
                <div className="row">
                  <Slider slides={banners} />
                </div>
              </div>
            </div>

            {data.map((item, index) => {
              //console.log("item", item);
              if (item?.tileType == "vertical") {
                return (
                  <div
                    className="video-carousel-area vfx-item-ptb d-flex"
                    key={index}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="vfx-item-section">
                            <h3>{item.categoryName}</h3>
                            {item.items?.length > 4 && (
                              <span class="view-more">
                                <Link
                                  to={`/category/${item.categoryId}`}
                                  title="view-more"
                                  state={{ name: item.categoryName }}
                                >
                                  View All
                                </Link>
                              </span>
                            )}
                          </div>
                          <div className="video-carousel owl-carousel overflow-splider">
                            <CategorySlider slides={item.items} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="video-shows-section vfx-item-ptb">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="vfx-item-section">
                            <h3>{item.categoryName}</h3>
                            {item.items?.length > 4 && (
                              <span class="view-more">
                                <Link
                                  to={`/category/${item.categoryId}`}
                                  title="view-more"
                                >
                                  View All
                                </Link>
                              </span>
                            )}
                          </div>
                          <div className="video-shows-carousel owl-carousel  overflow-splider">
                            <CategorySliderWithTitle series={item.items} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div className="eupopup eupopup-bottom"></div>
          </>
        </>
      )}
    </>
  );
}
