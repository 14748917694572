import React, { useEffect } from "react";
import { Link } from "react-router-dom";
function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="breadcrumb-section bg-xs"
        // style="background-image: url(/images/breadcrum-bg.jpg)"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h2> Refund and Return Policy</h2>
              {/* <nav id="breadcrumbs">
                <ul>
                  <li>
                    <Link to="/" title="Home">
                      Home
                    </Link>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </div>

      <div className="details-page-area vfx-item-ptb vfx-item-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="details-item-block">
                <h4>
                  <strong>
                    Refund and Return Policy of&nbsp;
                    <span className="highlight preview_company_name">
                      Sedusa
                    </span>
                  </strong>
                </h4>
                <br />
                <p>
                  SEDUSA OTT offers a Refund Policy for subscribed packages:
                </p>
                <h5>
                  <b>Subscription Refunds:</b>
                </h5>
                <br />
                <p>
                  {" "}
                  Subscription fees are non-refundable. Upon cancellation,
                  access to premium content continues until the end of the
                  current billing cycle.
                </p>
                <br />
                <h5>
                  <b>Exceptional Circumstances:</b>
                </h5>
                <br />
                <p>
                  Refunds may be considered in exceptional cases, subject to
                  review by SEDUSA OTT. Contact our support team with specific
                  concerns.
                </p>
                <h5>
                  <b>Third-Party Services: </b>
                </h5>
                <br />{" "}
                <p>
                  SEDUSA OTT is not liable for refunds related to third-party
                  services or tools accessed through our platform. No Guarantee:
                  We do not guarantee refunds for dissatisfaction with content,
                  as subscription fees grant access to a range of content and
                  not specific items.
                </p>
                <h5>
                  <b>Policy Changes:</b>
                </h5>
                <br />{" "}
                <p>
                  SEDUSA OTT reserves the right to modify the Refund Policy. Any
                  changes will be effective upon posting on the
                  website/application. For further inquiries or refund-related
                  concerns, contact our support team. Contact Information For
                  any inquiries, concerns, or to initiate a return, please
                  contact our customer support team at sedusasaott@gmail.com
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RefundPolicy;
