import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import "../style/signIn.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sedusaSignIn, sedusaVerify } from "../network/service";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
export default function ValidateOTP() {
  const navigate = useNavigate();
  //console.log("isLoggedIn signin");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // Validation state
  const [validationError, setValidationError] = useState({
    email: "",
    password: "",
  });
  const [submit, setSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = async () => {
    let isValid = true;
    let errors = {};
    setValidationError({ ...errors });
    return isValid;
  };

  const handleCancel = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/";
  };

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      localStorage.removeItem("isLoggedIn");
      validateForm()
        .then(async (valid) => {
          if (valid) {
            setSubmit(true);
            // Make the API call to signup
            const result = await sedusaVerify({
              otp: formData.email,
            });
            // Show success toast
            toast.success("Sign in successful!", {
              position: toast.POSITION.TOP_CENTER,
            });
            //console.log("result", result);
            // Reset the form data

            localStorage.setItem("isLoggedIn", "TRUE");
            localStorage.setItem("isVerified", true);
            // localStorage.setItem("accessToken", result.data.access_token);
            setTimeout(() => {
              let path =
                localStorage.getItem("isSubscribedUser") != "false"
                  ? "/validateOTP"
                  : "/subscriptions";
              //console.log("path", path);
              window.location.href = path;
              // navigate(path); // Navigate to the home page after sign-in}
              setSubmit(false);
            }, 2000);

            setFormData({
              email: "",
              password: "",
            });
          } else {
            setSubmit(false);
            toast.error("Please fill mandatory fields");
          }
        })
        .catch((error) => {
          setSubmit(false);
          toast.error("Something went wrong!");
        });
    } catch (error) {
      // Handle signup error

      // Show error toast
      toast.error(error.response?.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <>
      <div id="main-wrapper">
        <div className="container-fluid px-0 m-0 h-100 mx-auto">
          <div className="row g-0 min-vh-100 overflow-hidden">
            <div className="col-md-12">
              <div className="hero-wrap d-flex align-items-center h-100">
                <div className="hero-mask"></div>
                <div
                  className="hero-bg hero-bg-scroll"
                  style={{
                    backgroundImage: `url(/images/login-signup-bg-img.jpg)`,
                  }}
                ></div>
                <div className="hero-content mx-auto w-100 h-100 d-flex flex-column justify-content-center">
                  {/* <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  > */}
                  <div className="row">
                    <div className="col-12 col-lg-5 col-xl-5 mx-auto">
                      <div className="logo mt-40 mb-20 justify-content-center d-flex text-center">
                        {/* <a href="/" title="logo">
                          <img
                            src="/images/180 X 34.png"
                            alt="logo"
                            title="logo"
                            className="login-signup-logo"
                          />
                        </a> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6 mx-auto d-flex align-items-center login-item-block">
                    <div className="container login-part">
                      <div className="row">
                        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
                          <h2
                            className="form-title-item mb-4"
                            style={{ color: "white" }}
                          >
                            Validate OTP (Check E-Mail)
                          </h2>
                          <form method="POST" className="">
                            <div className="message"></div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                className="form-control"
                                placeholder="OTP"
                                color="white"
                                style={{ color: "white" }}
                                onChange={handleInputChange}
                              />
                              {validationError.email?.length > 0 && (
                                <p className="text-danger">
                                  {validationError.email}
                                </p>
                              )}
                            </div>

                            <button
                              className="btn-submit btn-block my-4 mb-1"
                              type="submit"
                              onClick={handleVerify}
                              disabled={submit}
                            >
                              {!submit ? "Verify" : "Processing"}
                            </button>
                            <button
                              className="btn-submit btn-block my-4 mb-4"
                              onClick={handleCancel}
                              disabled={submit}
                            >
                              {"Cancel"}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: "9",
            width: "100%",
          }}
        >
          <Footer />
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
