import React from "react";
import "../style/trailerPlayer.css";
import "video-react/dist/video-react.css"; // Import the styles
import VideoJS from "../player/VideoJSPlayer";
import "video.js";
import "videojs-youtube";

function TrailerPlayer({ trailerURL, image }) {
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 z-2">
          <div className="detail-poster-area sedusa-trailer-area ">
            <VideoJS url={trailerURL} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrailerPlayer;
