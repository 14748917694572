import React, { useEffect, useState } from "react";
import "../style/mainStyle.css";
import { Link } from "react-router-dom";

import Loader from "../utils/Loader";
function ShowDetails({ data }) {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="page-content-area vfx-item-ptb pt-3">
          <div className="container-fluid">
            <div className="row" style={{ padding: "inherit" }}>
              <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 mb-4">
                <div className="detail-poster-area">
                  <div className="play-icon-item"></div>
                  <div className="video-post-date">
                    <div className="video-watch-share-item">
                      <span className="video-imdb-view">
                        <img
                          src="/images/imdb-logo.png"
                          alt="imdb-logo"
                          title="imdb-logo"
                        />
                        {data?.imdbRating}
                      </span>
                      &nbsp;
                      <span className="btn-share"></span>
                    </div>
                  </div>

                  <div
                    className="dtl-poster-img"
                    style={{ minHeight: "fit-content" }}
                  >
                    <img
                      src={data.thumbnail_horizontal}
                      alt="tv-series-poster-1"
                      title={data?.name}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 mb-4">
                <div className="poster-dtl-item">
                  <h2>
                    <a title="Show Name">{data?.name}</a>
                  </h2>

                  <ul className="dtl-list-link dtl-link-col">
                    <li>
                      <a href="#" title="Seasons">
                        {data?.showSeason?.length} Seasons
                      </a>
                    </li>
                  </ul>
                  <ul className="dtl-list-link">
                    {data.category?.map((category, index) => {
                      return (
                        <li key={`index-${index}`}>
                          <h6
                            style={{
                              color: "white",
                              fontSize: "16px",
                              fontWeight: 700,
                            }}
                          >
                            {category.name}
                          </h6>
                        </li>
                      );
                    })}
                  </ul>
                  <h3>{data?.description}</h3>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "inherit" }}>
              <div className="video-shows-section vfx-item-ptb tv-season-related-block">
                <div className="container-fluid">
                  <div className="row" style={{ padding: "inherit" }}>
                    <div className="col-md-12 p-0">
                      <div className="vfx-item-section">
                        <h3>Seasons </h3>
                      </div>
                      <div
                        className="tv-season-video-carousel owl-carousel season-align"
                        style={{
                          display: "flex",
                        }}
                      >
                        {data?.showSeason?.map((item, index) => {
                          return (
                            <div
                              className="single-video show-details-single-video"
                              style={{ marginRight: "10px" }}
                              key={`index-${index}`}
                            >
                              <Link
                                to={`/movie/details/${item?.id}`}
                                state={{ type: "SEASON" }}
                                title="Season 1"
                              >
                                <div className="video-img">
                                  <img
                                    src={item.thumbnail_horizontal}
                                    alt="Season number"
                                  />
                                </div>
                                <div className="season-title-item">
                                  <h3>{item.title}</h3>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ShowDetails;
