import React, { useState, useEffect } from "react";
import "./style/account.css";
import service from "../network/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../utils/Loader";
function Profile() {
  let user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState(user);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    fetchProfile();
  }, []);

  const handleProfileUpdate = () => {
    // Add logic for profile update
  };

  const readURL = (input) => {
    // Add logic to read and display the selected profile image
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        document.getElementById("profile-image-preview").src = e.target.result;
      };

      reader.readAsDataURL(input.files[0]);
    }
  };
  const fetchProfile = () => {
    service.fetchProfile().then((data) => {
      setData(data.data);
      setLoader(false);
    });
  };
  const updateProfile = (e) => {
    e.preventDefault();
    let profileData = {
      firstName: data.firstName,
      lastName: data.lastName,
      id: user.id,
    };
    if (data.password && data.password.trim().length > 0) {
      profileData.password = data.password;
    }
    service
      .updateProfile(profileData)
      .then(async (data) => {
        toast.success("Updated your profile");
        await fetchProfile();
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="sedusa-profile-wrapper">
          <div className="sedusa-profile-header">
            <span className="sedusa-profile-title">Edit profile</span>
          </div>
          <div className="edit-profile-area vfx-item-ptb vfx-item-info">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 offset-lg-2 offset-md-0">
                  <div className="edit-profile-form">
                    <form
                      onSubmit={handleProfileUpdate}
                      acceptCharset="UTF-8"
                      className="row"
                      name="profile_form"
                      id="profile_form"
                      role="form"
                      encType="multipart/form-data"
                    >
                      <input
                        type="hidden"
                        name="_token"
                        value="IqyrgTsyMVyXk1IJHKIRMUGTaKj06thmycRN3gql"
                      />
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group mb-3">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={data.firstName}
                            className="form-control"
                            required
                            onChange={(e) => {
                              setData({ ...data, firstName: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group mb-3">
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={data.lastName}
                            className="form-control"
                            required
                            onChange={(e) => {
                              setData({ ...data, lastName: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group mb-3">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={data.email}
                            className="form-control"
                            required
                            onChange={(e) => {
                              setData({ ...data, email: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group mb-3">
                          <label>Password</label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            value={data?.password ? data.password : null}
                            onChange={(e) => {
                              //console.log();
                              setData({ ...data, password: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group mb-3">
                          <label>Phone</label>
                          <input
                            type="number"
                            name="phone"
                            id="phone"
                            value=""
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group mb-3">
                      <label>Address</label>
                      <textarea
                        name="user_address"
                        id="user_address"
                        className="form-control"
                        cols="30"
                        rows="4"
                      ></textarea>
                    </div>
                  </div> */}
                      {/* <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div className="form-group mb-3">
                      <label>Profile Image</label>
                      <label className="browse_pic_file">
                        <input
                          type="file"
                          id="user_image"
                          name="user_image"
                          aria-label="Profile picture browse"
                          onChange={(e) => readURL(e.target)}
                        />
                        <span className="browse_file_custom"></span>
                      </label>
                      <div className="user_pic_view">
                        <div className="fileupload_img">
                          <img
                            id="profile-image-preview"
                            className="fileupload_img"
                            src="/images/user-avatar.png"
                            alt="profile pic"
                            title="profile pic"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="form-group d-flex align-items-end flex-column mt-30">
                          <button
                            type="submit"
                            className="vfx-item-btn-danger text-uppercase"
                            onClick={updateProfile}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Profile;
