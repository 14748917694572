import React, { useState } from "react";
import "./style/account.css";
import service from "../network/service";
import { ToastContainer, toast } from "react-toastify";

function ContactUs() {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    messageBody: "",
    mobile: "",
  });
  const handleContactUs = async (e) => {
    e.preventDefault();
    try {
      await service.sendContactUs(data);
      setData({
        name: "",
        email: "",
        subject: "",
        messageBody: "",
        mobile: "",
      });
    } catch (error) {
      toast.error("Please check you inputs");
    }
  };

  return (
    <div className="sedusa-profile-wrapper">
      <div className="sedusa-profile-header">
        <span className="sedusa-profile-title">Contact Us</span>
      </div>
      <div className="edit-profile-area vfx-item-ptb vfx-item-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 offset-lg-2 offset-md-0">
              <div className="edit-profile-form">
                <form
                  onSubmit={handleContactUs}
                  acceptCharset="UTF-8"
                  className="row"
                  name="profile_form"
                  id="profile_form"
                  role="form"
                  encType="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="IqyrgTsyMVyXk1IJHKIRMUGTaKj06thmycRN3gql"
                  />
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group mb-3">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        required
                        value={data.name}
                        onChange={(e) =>
                          setData((old) => {
                            return {
                              ...old,
                              name: e.target.value,
                            };
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        required
                        value={data.email}
                        onChange={(e) =>
                          setData((old) => {
                            return {
                              ...old,
                              email: e.target.value,
                            };
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group mb-3">
                      <label>Phone</label>
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        className="form-control"
                        value={data.mobile}
                        onChange={(e) =>
                          setData((old) => {
                            return {
                              ...old,
                              mobile: e.target.value,
                            };
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group mb-3">
                      <label>Subject</label>
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        value={data.subject}
                        onChange={(e) =>
                          setData((old) => {
                            return {
                              ...old,
                              subject: e.target.value,
                            };
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group mb-3">
                      <label>Message</label>
                      <textarea
                        name="user_address"
                        id="user_address"
                        className="form-control"
                        cols="30"
                        rows="4"
                        value={data.messageBody}
                        onChange={(e) =>
                          setData((old) => {
                            return {
                              ...old,
                              messageBody: e.target.value,
                            };
                          })
                        }
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="form-group d-flex align-items-start flex-column mt-30">
                      <button
                        type="submit"
                        className="vfx-item-btn-danger text-uppercase"
                      >
                        Send Email
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ContactUs;
