import apis from "../network/service";

export const getMimeType = (url) => {
  const urlSub = url.split("/");
  if (["www.youtube.com", "youtube.com"].includes(urlSub[2])) {
    return "video/youtube";
  }
  console.log(urlSub);
  return getMimeTypeFromExtension(`${urlSub[urlSub.length - 1].split(".")[1]}`);
};

function getMimeTypeFromExtension(extension) {
  return videFormats[extension];
}

const videFormats = {
  "3gp": "video/3gpp",
  "3gpp": "video/3gpp",
  "3g2": "video/3gpp2",
  h261: "video/h261",
  h263: "video/h263",
  h264: "video/h264",
  m4s: "video/iso.segment",
  jpgv: "video/jpeg",
  jpm: "video/jpm",
  jpgm: "video/jpm",
  mj2: "video/mj2",
  mjp2: "video/mj2",
  ts: "video/mp2t",
  mp4: "video/mp4",
  mp4v: "video/mp4",
  mpg4: "video/mp4",
  mpeg: "video/mpeg",
  mpg: "video/mpeg",
  mpe: "video/mpeg",
  m1v: "video/mpeg",
  m2v: "video/mpeg",
  ogv: "video/ogg",
  qt: "video/quicktime",
  mov: "video/quicktime",
  uvh: "video/vnd.dece.hd",
  uvvh: "video/vnd.dece.hd",
  uvm: "video/vnd.dece.mobile",
  uvvm: "video/vnd.dece.mobile",
  uvp: "video/vnd.dece.pd",
  uvvp: "video/vnd.dece.pd",
  uvs: "video/vnd.dece.sd",
  uvvs: "video/vnd.dece.sd",
  uvv: "video/vnd.dece.video",
  uvvv: "video/vnd.dece.video",
  dvb: "video/vnd.dvb.file",
  fvt: "video/vnd.fvt",
  mxu: "video/vnd.mpegurl",
  m4u: "video/vnd.mpegurl",
  pyv: "video/vnd.ms-playready.media.pyv",
  uvu: "video/vnd.uvvu.mp4",
  uvvu: "video/vnd.uvvu.mp4",
  viv: "video/vnd.vivo",
  webm: "video/webm",
  f4v: "video/x-f4v",
  fli: "video/x-fli",
  flv: "video/x-flv",
  m4v: "video/x-m4v",
  mkv: "video/x-matroska",
  mk3d: "video/x-matroska",
  mks: "video/x-matroska",
  mng: "video/x-mng",
  asf: "video/x-ms-asf",
  asx: "video/x-ms-asf",
  vob: "video/x-ms-vob",
  wm: "video/x-ms-wm",
  wmv: "video/x-ms-wmv",
  wmx: "video/x-ms-wmx",
  wvx: "video/x-ms-wvx",
  avi: "video/x-msvideo",
  movie: "video/x-sgi-movie",
  smv: "video/x-smv",
  m3u8: "application/vnd.apple.mpegurl",
};

const EVENTS = [
  // HTMLMediaElement events
  "abort",
  "canplay",
  "canplaythrough",
  "durationchange",
  "emptied",
  "ended",
  "error",
  "loadeddata",
  "loadedmetadata",
  "loadstart",
  "pause",
  "play",
  "playing",
  "progress",
  "ratechange",
  "seeked",
  "seeking",
  "stalled",
  "suspend",
  "timeupdate",
  "volumechange",
  "waiting",

  // HTMLVideoElement events
  "enterpictureinpicture",
  "leavepictureinpicture",

  // Element events
  "fullscreenchange",
  "resize",

  // video.js events
  "audioonlymodechange",
  "audiopostermodechange",
  "controlsdisabled",
  "controlsenabled",
  "debugon",
  "debugoff",
  "disablepictureinpicturechanged",
  "dispose",
  "enterFullWindow",
  "error",
  "exitFullWindow",
  "firstplay",
  "fullscreenerror",
  "languagechange",
  "loadedmetadata",
  "loadstart",
  "playerreset",
  "playerresize",
  "posterchange",
  "ready",
  "textdata",
  "useractive",
  "userinactive",
  "usingcustomcontrols",
  "usingnativecontrols",
];

export const handleVideoPlayerEvent = async (player, videoDetails) => {
  let interval;

  if (player) {
    player.on("play", async function () {
      const currentTime = player.currentTime();
      console.log("dureaudjlkasdkjhas", player.currentTime());
      currentTime == 0 &&
        (await handlePlayerTimeUpdateEvent(
          player.currentTime(),
          player.duration(),
          { ...videoDetails, type: "VIDEO_STARTED" }
        ));
    });

    player.on("playing", async function () {
      interval = setInterval(async () => {
        if (player) {
          await handlePlayerTimeUpdateEvent(
            player.currentTime(),
            player.duration(),
            { ...videoDetails, type: "VIDEO_TIME_UPDATE" }
          );
        }
      }, 10000);
    });

    player.on("seeked", async function () {
      await handlePlayerTimeUpdateEvent(
        player.currentTime(),
        player.duration(),
        { ...videoDetails, type: "VIDEO_TIME_UPDATE" }
      );
    });

    player.on("ended", async () => {
      await handlePlayerTimeUpdateEvent(
        player.currentTime(),
        player.duration(),
        { ...videoDetails, type: "VIDEO_COMPLETED" }
      );
    });

    player.on("pause", async function () {
      clearInterval(interval);
      await handlePlayerTimeUpdateEvent(
        player.currentTime(),
        player.duration(),
        { ...videoDetails, type: "VIDEO_PAUSED" }
      );
    });

    player.on("error", async function () {
      clearInterval(interval);
    });

    player.on("abort", async function () {
      clearInterval(interval);
    });

    player.on("stalled", async function () {
      clearInterval(interval);
    });

    player.on("dispose", () => {
      clearInterval(interval);
    });
  }
};

const handlePlayerTimeUpdateEvent = async (currentTime, duration, data) => {
  const processIntervalInSeconds = 60;

  console.log(currentTime, duration);
  const timeInMinutes = Math.floor(currentTime / processIntervalInSeconds);
  const timeInSeconds = Math.floor(
    currentTime - timeInMinutes * processIntervalInSeconds
  );

  console.log(
    `Completed time, ${timeInMinutes}: ${timeInSeconds},  timeInMinutes + timeInSeconds :${
      timeInMinutes * 60 + timeInSeconds
    }`
  );
  console.log({ ...data, totalDurationPlayed: timeInSeconds });
  await apis.publishEvent({ ...data, totalDurationPlayed: timeInSeconds });
};
