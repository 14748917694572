import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "../style/mainStyle.css";
import "../style/UpcomingSplider.css";
import { Link } from "react-router-dom";

const CategorySlider = ({ slides }) => (
  <Splide
    id="sadjd"
    className="custom-slide"
    options={{
      waitForTransition: true,
      heightRatio: 0.45,
      type: "slide",
      padding: {
        top: "2rem",
      },
      gap: "1rem",
    }}
  >
    {slides.map((slide, index) => (
      <SplideSlide key={index} className="customStyle">
        <div className="single-video owl-img-wrapper">
          {console.log(`slide`, slide)}
          <Link
            to={{
              pathname: slide.linkTo,
              search: `?watch=${slide?.resumeTimeInSeconds || 0}`,
            }}
            title={slide.title}
            state={{ type: slide.itemType }}
          >
            <div className="video-img owl-img-div">
              <span className="video-item-content">{slide.title}</span>
              <img
                src={slide.imageSrc}
                alt={slide.title}
                title={slide.title}
                className="owl-img"
              />
            </div>
          </Link>
        </div>
      </SplideSlide>
    ))}
  </Splide>
);

export default CategorySlider;
