import React from "react";
import "./style/jumbotron.css";

export default function Jumbotron({
  children,
  direction = "row",
  ...restProps
}) {
  return (
    <div className="Sedusaitem" style={{ flexDirection: direction }}>
      <div className="Sedusainner" style={{ flexDirection: direction }}>
        {children}
      </div>
    </div>
  );
}

Jumbotron.Container = function JumbotronContainer({ children, ...restProps }) {
  return (
    <div className="Sedusacontainer" {...restProps}>
      {children}
    </div>
  );
};

Jumbotron.Pane = function JumbotronPane({ children, ...restProps }) {
  return (
    <div className="Sedusapane" {...restProps}>
      {children}
    </div>
  );
};

Jumbotron.Title = function JumbotronTitle({ children, ...restProps }) {
  return (
    <h1 className="Sedusatitle" {...restProps}>
      {children}
    </h1>
  );
};

Jumbotron.SubTitle = function JumbotronSubTitle({ children, ...restProps }) {
  return (
    <h2 className="SedusasubTitle" {...restProps}>
      {children}
    </h2>
  );
};

Jumbotron.Image = function JumbotronImage({ ...restProps }) {
  return <img className="Sedusaimage" {...restProps} />;
};
