import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import "../style/VideoPlayer.css";
import "video-react/dist/video-react.css"; // Import the styles
import VideoJS from "../player/VideoJSPlayer";

function VideoPlayer({
  thumbnail,
  url,
  id,
  name,
  verticalImage,
  resumeSecond,
}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePosterClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
          <div className="detail-poster-area">
            {isPlaying ? (
              <VideoJS
                url={url}
                id={id}
                poster={thumbnail}
                name={name}
                verticalImage={verticalImage}
                resumeSecond={resumeSecond}
              />
            ) : (
              <>
                <div className="play-icon-item">
                  <div onClick={handlePosterClick} className="icon">
                    <FontAwesomeIcon icon={faPlay} />
                    {/* <span className="ripple"></span> */}
                  </div>
                </div>
                <div className="dtl-poster-img">
                  <img
                    className="video-player-img-div"
                    src={thumbnail}
                    alt="thumbnail"
                    style={{ maxHeight: "450px" }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
