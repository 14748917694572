import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "../style/mainStyle.css";
import { Link } from "react-router-dom";

const Slide = ({ title, imageSrc, linkTo, type }) => {
  return (
    <SplideSlide>
      <div className="splide-slider-details-area">
        <Link to={linkTo} title="" state={{ type: type }}>
          <div className="splide-slider-details-area-details">
            <h1
              style={{
                minWidth: "80vw",
                textShadow: "black 0px 0px 22px",
              }}
            >
              {title}
            </h1>
            <Link
              to={linkTo}
              title={title}
              state={{ type: type }}
              className="btn-watch"
            >
              <img src="/images/ic-play.png" alt="ic-play" title="ic-play" />
              Watch
            </Link>
            {/* <Link to={linkTo} className="btn-buy-plan" title="buy-plan">
              <img
                src="/images/ic-subscribe.png"
                alt="ic-subscribe"
                title="ic-subscribe"
              />
              Buy Plan
            </Link> */}
            {/* </a> */}
          </div>
          <img
            src={imageSrc}
            title={title}
            alt={title}
            style={{
              borderRadius: "12px",
            }}
          />
        </Link>
      </div>
    </SplideSlide>
  );
};

const Slider = ({ slides }) => (
  <Splide
    id="sda"
    className="banner-top"
    options={{
      heightRatio: 0.5,
      type: "loop",
      gap: "2rem",

      padding: {
        right: "5rem",
        left: "5rem",
        top: "1rem",
      },
      breakpoints: {
        767: {
          padding: {
            right: "0rem",
            left: "0rem",
            gap: "0rem",
          },
        },
      },
      // autoplay: true,
      // perPage: ,
      rewind: true,
      focus: "center",
      pauseOnHover: false,
      pauseOnFocus: false,
      pagination: false,
      perPage: 1,
    }}
  >
    {slides.map((slide, index) => (
      <Slide key={index} {...slide} />
    ))}
  </Splide>
);

export default Slider;
