import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "../style/mainStyle.css";
import "../style/UpcomingSplider.css";
import { Link } from "react-router-dom";

const CategorySliderWithTitle = ({ series }) => {
  return (
    <Splide
      className="custom-slide"
      options={{
        heightRatio: 0.45,
        type: "slide",
        padding: {
          top: "2rem",
        },
        gap: "1em",
      }}
    >
      {series.map((series, index) => (
        <SplideSlide key={index} className="customStyle customStyle2">
          <div className="single-video owl-img-wrapper">
            {console.log(`series`, series)}
            <Link
              to={{
                pathname: series.linkTo,
                search: `?watch=${series?.resumeTimeInSeconds || 0}`,
              }}
              title={series.title}
              state={{ type: series.itemType }}
            >
              <div className="video-img owl-img-div">
                <span className="video-item-content">{series.title}</span>
                <img
                  src={series.imageSrc}
                  alt={series.title}
                  title={series.title}
                  className="owl-img"
                />
              </div>
            </Link>
          </div>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default CategorySliderWithTitle;
