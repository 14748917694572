import React, { useEffect } from "react";
import { Link } from "react-router-dom";
function CancellationPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="breadcrumb-section bg-xs"
        // style="background-image: url(/images/breadcrum-bg.jpg)"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h2> CANCELLATION POLICY</h2>
              {/* <nav id="breadcrumbs">
                <ul>
                  <li>
                    <Link to="/" title="Home">
                      Home
                    </Link>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </div>

      <div className="details-page-area vfx-item-ptb vfx-item-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="details-item-block">
                <h4>
                  <strong>
                    Cancellation policy of&nbsp;
                    <span className="highlight preview_company_name">
                      Sedusa
                    </span>
                  </strong>
                </h4>
                <br />
                <p>
                  At SEDUSA OTT, providing a user-friendly experience is our
                  priority. This Cancellation Policy outlines the terms and
                  procedures regarding subscription cancellations.
                </p>
                <h5>
                  <b>1. Subscription Cancellation</b>
                </h5>
                <br />
                <p>
                  Cancellation Window: Users have the flexibility to cancel
                  their subscription at any time during their active
                  subscription period. Upon cancellation, the subscription will
                  remain active until the end of the current billing cycle.
                  Cancellation Methods: Users can initiate the cancellation
                  process by accessing their account settings on our platform.
                  Within the account dashboard, navigate to the "Subscription"
                  or "Account" section to locate the cancellation option.
                  Alternatively, users can contact our customer support team via
                  sedusaott@gmail.com to request assistance with the
                  cancellation process. Effective Cancellation Date: The
                  cancellation becomes effective at the conclusion of the
                  ongoing billing cycle, allowing users to access the subscribed
                  content until the end of the paid period.
                </p>
                <br />
                <h5>
                  <b>2. Refund Policy for Subscription Cancellations</b>
                </h5>
                <br />
                <p>
                  Refund Eligibility: As an OTT platform, subscription
                  cancellation does not entail a pro-rata refund for the
                  remaining unused portion of the subscription period. Users
                  will retain access to the subscribed content until the end of
                  the active billing cycle post-cancellation and will not be
                  charged for subsequent billing periods. Trial Periods: For
                  users within a trial period, canceling the subscription during
                  the trial will prevent any charges and immediate access to the
                  subscription content will cease.
                </p>
                <h5>
                  <b>3. Exceptions and Additional Information</b>
                </h5>
                <br />{" "}
                <p>
                  Prorated Refunds Clarification: To ensure clarity, it's
                  important to emphasize that no refunds or partial
                  reimbursements are issued for the remaining duration of the
                  subscription period post-cancellation. Subscription Add-Ons or
                  Upgrades: Users may have subscribed to additional services or
                  upgrades. Cancellation of the main subscription plan may
                  affect these add-ons or upgrades, and access to them might
                  cease upon subscription cancellation.
                </p>
                <h5>
                  <b>4. Contact Information</b>
                </h5>
                <br />{" "}
                <p>
                  For any queries, assistance with subscription cancellations,
                  or further information, users can reach our dedicated support
                  team at sedusaott@gmail.com. Our support team is available to
                  guide users through the cancellation process or address any
                  concerns related to their subscription or account management.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancellationPolicy;
