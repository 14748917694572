import React, { useEffect } from "react";
import { Link } from "react-router-dom";
function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="breadcrumb-section bg-xs"
        // style="background-image: url(/images/breadcrum-bg.jpg)"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h2>Privacy Policy</h2>
              {/* <nav id="breadcrumbs">
                <ul>
                  <li>
                    <Link to="/" title="Home">
                      Home
                    </Link>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </div>

      <div className="details-page-area vfx-item-ptb vfx-item-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="details-item-block">
                <h4>
                  <strong>
                    Privacy Policy of&nbsp;
                    <span className="highlight preview_company_name">
                      Sedusa
                    </span>
                  </strong>
                </h4>
                <p>
                  This Privacy Policy governs the collection, use, disclosure,
                  and protection of personal information by SEDUSA OTT PRIVATE
                  LIMITED in compliance with the Indian Digital Data Protection
                  Act 2023 and its regulations.
                </p>
                <br />
                <h5>
                  <b>1. Information Collection and Use</b>
                </h5>{" "}
                <br />
                <p>
                  Types of Data Collected: We collect various types of personal
                  information, such as name, contact details, address, payment
                  information, and browsing history, to provide and improve our
                  services, process orders, communicate with users, and
                  customize user experience. Legal Basis for Processing: Your
                  personal information is processed based on lawful grounds,
                  including the performance of contracts, compliance with legal
                  obligations, legitimate business interests, and explicit
                  consent where required.
                </p>
                <br></br>
                <h5>
                  <b>2. Data Processing and Consent</b>
                </h5>{" "}
                <br />
                <p>
                  {" "}
                  Processing Purposes: Your data is processed for specific
                  purposes related to the provision of services, account
                  management, billing, communication, marketing activities, user
                  preferences and search history. We do not process personal
                  information for purposes incompatible with the initial
                  collection purposes. Consent: By using our services, you
                  provide consent to the collection, processing, and storage of
                  your personal information as outlined in this policy. You have
                  the right to withdraw consent at any time.
                </p>
                <br />
                <h5>
                  <b>3. Data Security and Retention</b>
                </h5>{" "}
                <br />
                <p>
                  Data Security Measures: We employ industry-standard security
                  measures to protect your personal information from
                  unauthorized access, misuse, alteration, or disclosure. These
                  measures include encryption, firewalls, access controls, and
                  regular security assessments. Data Retention: Personal
                  information is retained only for as long as necessary to
                  fulfill the purposes outlined in this policy or as required by
                  applicable laws. Upon reaching the end of the retention
                  period, data is securely deleted or anonymized.
                </p>
                <br></br>
                <h5>
                  <b>4. User Rights</b>
                </h5>{" "}
                <br />
                <b></b>
                <p>
                  Access and Correction: You have the right to access, correct,
                  or update your personal information held by us. You can do so
                  by accessing your account settings or contacting our data
                  protection officer. Withdrawal of Consent: You may withdraw
                  your consent for specific processing activities where consent
                  is the legal basis for processing. Withdrawal of consent does
                  not affect the lawfulness of processing based on consent
                  before its withdrawal.
                </p>
                <br />
                <h5>
                  <b>5. Disclosure to Third Parties</b>
                </h5>{" "}
                <br />
                <p>
                  /Third-Party Service Providers: We may share personal
                  information with trusted third-party service providers, such
                  as payment processors, shipping companies, marketing
                  platforms, media agencies, cinema houses, production
                  companies, film distributors, critics, publishers, cine
                  associations, prize and award foundations, viewership
                  assessment, ranking and rating agencies to facilitate services
                  or comply with legal requirements. These parties are
                  contractually bound to protect the confidentiality and
                  security of your data. Legal Disclosure: We may disclose
                  personal information in response to lawful requests by public
                  authorities, including to meet national security or law
                  enforcement requirements.
                </p>
                <br />
                <h5>
                  <b>6. Cookies and Tracking Technologies</b>
                </h5>{" "}
                <br />
                <p>
                  Cookie Usage: We use cookies and similar technologies to
                  collect information about your browsing behavior, preferences,
                  and device information to enhance user experience, analyze
                  trends, and personalize content. You can manage cookie
                  preferences through your browser settings or opt-out of
                  certain tracking technologies.
                </p>
                <br />
                <h5>
                  <b>7. Children's Privacy</b>
                </h5>{" "}
                <br />
                <p>
                  Age Limit: Our services are not directed at individuals under
                  the age of 18 without parental consent. We do not knowingly
                  collect or process personal information from minors without
                  appropriate consent or authorization.
                </p>
                <br />
                <h5>
                  <b>8. Updates to Privacy Policy</b>
                </h5>{" "}
                <br />
                <p>
                  Policy Changes: We reserve the right to update this Privacy
                  Policy to reflect changes in data processing practices, legal
                  requirements, or service updates. We will notify users of any
                  significant changes through prominent notices on our website
                  or via email.
                </p>
                <br />
                <h5>
                  <b>9. Contact Information</b>
                </h5>{" "}
                <br />
                <p>
                  For inquiries, requests, or concerns regarding your personal
                  information, data processing practices, or this Privacy
                  Policy, please contact our Data Protection Officer at Name:
                  Email:sedusaott@gmail.com
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
