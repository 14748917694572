import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import "../style/signIn.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sedusaSignIn } from "../network/service";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
export default function SigIn() {
  const navigate = useNavigate();
  //console.log("isLoggedIn signin");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // Validation state
  const [validationError, setValidationError] = useState({
    email: "",
    password: "",
  });
  const [submit, setSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = async () => {
    let isValid = true;
    let errors = {};
    setValidationError({ ...errors });
    return isValid;
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      localStorage.removeItem("isLoggedIn");
      validateForm()
        .then(async (valid) => {
          if (valid) {
            setSubmit(true);
            // Make the API call to signup
            const result = await sedusaSignIn({
              email: formData.email,
              password: formData.password,
              channelId: process.env.REACT_APP_CHANNEL_ID || "",
              publisherId: process.env.REACT_APP_PUB_ID || "",
            });
            // Show success toast
            toast.success("Sign in successful!", {
              position: toast.POSITION.TOP_CENTER,
            });
            //console.log("result", result);
            // Reset the form data

            localStorage.setItem("isLoggedIn", "TRUE");
            localStorage.setItem("accessToken", result.data.access_token);
            localStorage.setItem("refreshToken", result.data.refresh_token);
            localStorage.setItem(
              "isSubscribedUser",
              result.data.current_subscription?.id ?? false
            );
            localStorage.setItem(
              "isVerified",
              result.data.user?.isVerified ?? false
            );
            localStorage.setItem("user", JSON.stringify(result.data.user));
            setTimeout(() => {
              let path = !result.data.user?.isVerified
                ? "/validateOTP"
                : localStorage.getItem("isSubscribedUser") != "false"
                ? "/"
                : "/subscriptions";
              // localStorage.getItem("isSubscribedUser") != "false"
              //   ? "/"
              //   : "/validateOTP";
              //console.log("path", path);
              window.location.href = path;
              // navigate(path); // Navigate to the home page after sign-in}
              setSubmit(false);
            }, 2000);

            setFormData({
              email: "",
              password: "",
            });
          } else {
            setSubmit(false);
            toast.error("Please fill mandatory fields");
          }
        })
        .catch((error) => {
          //console.log(error);
          setSubmit(false);
          toast.error(error?.response?.data?.errorResponse);
        });
    } catch (error) {
      // Handle signup error

      // Show error toast
      toast.error(error.response?.data?.errorResponse, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <>
      <div id="main-wrapper">
        <div className="container-fluid px-0 m-0 h-100 mx-auto">
          <div className="row g-0 min-vh-100 overflow-hidden">
            <div className="col-md-12">
              <div className="hero-wrap d-flex align-items-center h-100">
                <div className="hero-mask"></div>
                <div
                  className="hero-bg hero-bg-scroll"
                  style={{
                    backgroundImage: `url(/images/login-signup-bg-img.jpg)`,
                  }}
                ></div>
                <div className="hero-content mx-auto w-100 h-100 d-flex flex-column justify-content-center">
                  {/* <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  > */}
                  <div className="row">
                    <div className="col-12 col-lg-5 col-xl-5 mx-auto">
                      <div className="logo mt-40 mb-20 justify-content-center d-flex text-center">
                        <a href="/" title="logo">
                          {/* <img
                            src="/images/180 X 34.png"
                            alt="logo"
                            title="logo"
                            className="login-signup-logo"
                          /> */}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6 mx-auto d-flex align-items-center login-item-block">
                    <div className="container login-part">
                      <div className="row">
                        <div className="col-12 col-lg-12 col-xl-12 mx-auto">
                          <h2
                            className="form-title-item mb-4"
                            style={{ color: "white" }}
                          >
                            Login
                          </h2>
                          <form method="POST" className="">
                            <div className="message"></div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                className="form-control"
                                placeholder="Email"
                                color="white"
                                style={{ color: "white" }}
                                onChange={handleInputChange}
                              />
                              {validationError.email?.length > 0 && (
                                <p className="text-danger">
                                  {validationError.email}
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                name="password"
                                id="password"
                                value={formData.password}
                                className="form-control"
                                placeholder="Password"
                                onChange={handleInputChange}
                              />
                              {validationError.password?.length > 0 && (
                                <p className="text-danger">
                                  {validationError.password}
                                </p>
                              )}
                            </div>
                            <div className="row mt-4 forgotdiv">
                              <div className="col">
                                <div className="form-check custom-control custom-checkbox">
                                  <input
                                    id="remember-me"
                                    name="remember"
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  <label className="custom-control-label">
                                    Remember me
                                  </label>
                                </div>
                              </div>
                              {/* <div className="col text-end">
                              <a
                                href="/"
                                className="btn-link"
                                title="forgot password"
                              >
                                {" "}
                                Forgot Password?
                              </a>
                            </div> */}
                            </div>
                            <button
                              className="btn-submit btn-block my-4 mb-4"
                              type="submit"
                              onClick={handleSignup}
                              disabled={submit}
                            >
                              {!submit ? "Login" : "Processing"}
                            </button>
                          </form>
                          <p className="text-3 text-center mb-3">
                            Don't you have an account yet?{" "}
                            <Link
                              className="btn-link"
                              title="signup"
                              to="/signup"
                            >
                              Sign Up
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            zIndex: 2,
            width: "-webkit-fill-available",
          }}
        >
          <Footer />
        </div>

        <ToastContainer />
      </div>
    </>
  );
}
