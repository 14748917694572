import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="breadcrumb-section bg-xs"
        // style="background-image: url(/images/breadcrum-bg.jpg)"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h2>About Us</h2>
              {/* <nav id="breadcrumbs">
                <ul>
                  <li>
                    <Link to="/" title="Home">
                      Home
                    </Link>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </div>

      <div className="details-page-area vfx-item-ptb vfx-item-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div
                className="details-item-block"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h4>
                  <strong>SEDUSA OTT PRIVATE LIMITED</strong>
                </h4>
                <br />
                <p>
                  Welcome to Sedusa Ott Private Limited – where creativity meets
                  empowerment. We are a visionary video streaming channel
                  dedicated to empowering artists across the spectrum of
                  entertainment and culture. Our mission is to shine a spotlight
                  on diverse art forms, with a special focus on preserving and
                  promoting rural traditions such as folk art. Sedusa is
                  committed to providing a platform for filmmakers and creators
                  to showcase their talent through films, web series, and all
                  forms of entertainment videos. Join us on a journey of
                  artistic exploration and celebration.
                </p>
                <br />
                <h5>
                  <b>Email at us</b>
                </h5>{" "}
                <p>Sedusaott@gmail.com</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
