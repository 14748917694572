import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="breadcrumb-section bg-xs"
        // style="background-image: url(/images/breadcrum-bg.jpg)"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h2> TERMS AND CONDITIONS</h2>
              {/* <nav id="breadcrumbs">
                <ul>
                  <li>
                    <Link to="/" title="Home">
                      Home
                    </Link>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </div>

      <div className="details-page-area vfx-item-ptb vfx-item-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="details-item-block">
                <h4>
                  <strong>
                    TERMS AND CONDITIONS&nbsp;
                    <span className="highlight preview_company_name">
                      Sedusa
                    </span>
                  </strong>
                </h4>
                <p>
                  Welcome to SEDUSA OTT, operated by SEDUSA OTT PRIVATE LIMITED.
                  By creating an account and accessing our platform, you agree
                  to abide by the following terms and conditions:
                </p>
                <br />
                <h5>
                  <b>1. Acceptance of Terms</b>
                </h5>{" "}
                <br />
                <p>
                  By using SEDUSA OTT, including its website, applications, and
                  services, you acknowledge that you have read, understood, and
                  agree to comply with these Terms and Conditions, Privacy
                  Policy, and any additional guidelines or policies provided.
                </p>
                <br />
                <h5>
                  <b>2. Account Creation and User Responsibilities</b>{" "}
                </h5>{" "}
                <br />
                <p>
                  Account Registration: To access certain features or content on
                  SEDUSA OTT, users may be required to create an account by
                  providing accurate and complete information. Users are
                  responsible for maintaining the confidentiality of their
                  account credentials and are liable for all activities
                  conducted under their account. User Obligations: Users agree
                  to use SEDUSA OTT for lawful purposes and refrain from
                  engaging in any activity that could harm the platform, its
                  users, or violate any laws.
                </p>
                <h5>
                  <b>3. Age Restriction</b>
                </h5>{" "}
                <br />
                <p>
                  Minimum Age Requirement: Users must be at least 18 years old
                  to create an account and access SEDUSA OTT. By agreeing to
                  these Terms and Conditions, users confirm that they meet the
                  minimum age requirement stipulated by SEDUSA OTT. Parental or
                  Guardian Consent: Individuals below the specified minimum age
                  may only access SEDUSA OTT under the supervision or with the
                  consent of a parent or legal guardian who agrees to be bound
                  by these Terms and Conditions on behalf of the minor.
                  Responsibility for Account: Parents or guardians are
                  responsible for monitoring and supervising minors' usage of
                  SEDUSA OTT, including any content accessed or actions
                  performed. Termination of Accounts: SEDUSA OTT reserves the
                  right to terminate or suspend accounts found to be registered
                  by individuals below the specified minimum age without
                  parental or guardian consent.
                </p>
                <br />
                <h5>
                  <b>3. Content Usage and Intellectual Property</b>
                </h5>{" "}
                <br />
                <p>
                  Content Access: SEDUSA OTT provides access to a variety of
                  content, including but not limited to videos, images, audio,
                  and text. This content is for personal, non-commercial use and
                  is subject to copyright and intellectual property laws. SEDUSA
                  OTT respects intellectual property rights and complies with
                  copyright laws. Upon receiving a written notification of
                  claimed copyright infringement in accordance with the
                  Information Technology Act, 2000, and the Copyright Act, 1957,
                  SEDUSA OTT will promptly remove any infringing User
                  Submissions or content from the Website/Application. If you
                  believe copyrighted work has been infringed, please send an
                  Infringement Notification providing specific details and
                  evidence of infringement. By submitting this notification, you
                  acknowledge that SEDUSA OTT may share it with users involved.
                  Content Restrictions: Users agree not to reproduce, rent,
                  resell, modify, distribute, display, or exploit any content
                  from SEDUSA OTT without explicit authorization from the
                  respective rights holders. In such an event, such user shall
                  be solely responsible for such illegal/ unauthorized
                  activities in accordance with the law.
                </p>
                <br />
                <h5>
                  <b>4. Subscription and Billing</b>{" "}
                </h5>{" "}
                <br />
                <p>
                  Subscription Services: Users may have access to
                  subscription-based services offered by SEDUSA OTT. By
                  subscribing, users agree to the terms outlined in the
                  subscription agreement. To access exclusive Premium Content on
                  SEDUSA OTT, users must subscribe to available Subscription
                  Packages. Subscribing to these packages is open to all
                  Registered Users. However, Subscription Users must adhere to
                  terms specified by SEDUSA OTT. SEDUSA OTT retains the right to
                  modify or discontinue the Website/Application without prior
                  notice, and shall not be liable to You or any third party for
                  such actions. Moreover, SEDUSA OTT reserves the right to
                  change, terminate, or modify Subscription Plans, Fees, billing
                  cycles, or any part of the Services at its discretion,
                  effective upon posting on the site/app. The platform may
                  introduce, modify, or remove packaging, add-on packages, or
                  individual content subject to subscription as deemed
                  appropriate. SEDUSA OTT does not guarantee specific content
                  availability or minimum content for subscription fees.
                  Furthermore, SEDUSA OTT may withhold content in cases related
                  to national security, emergencies, content breaching laws, or
                  deemed objectionable, without obligation to provide such
                  content to users." Billing and Payments: Users are responsible
                  for all charges associated with their subscription, and
                  payment details provided must be accurate and current.
                </p>
                <br />
                <h5>
                  <b>5. Privacy and Data Protection</b>{" "}
                </h5>{" "}
                <br />
                <p>
                  Privacy Policy: SEDUSA OTT respects user privacy and handles
                  personal information in accordance with the Privacy Policy. By
                  using the platform, users agree to the collection, use, and
                  storage of their data as outlined in the Privacy Policy.
                </p>
                <br />
                <h5>
                  <b>6. Termination and Suspension</b>{" "}
                </h5>{" "}
                <br />
                <p>
                  Termination Rights: SEDUSA OTT reserves the right to terminate
                  or suspend user accounts at its discretion, especially in
                  cases of violating these Terms and Conditions or engaging in
                  prohibited activities.
                </p>
                <br />
                <h5>
                  <b>7. Limitation of Liability</b>{" "}
                </h5>{" "}
                <br />
                <p>
                  Liability Limitations: SEDUSA OTT PRIVATE LIMITED and its
                  affiliates shall not be liable for any direct, indirect,
                  incidental, consequential, or exemplary damages arising from
                  the use or inability to use the platform. User agree to
                  indemnify, defend, and hold harmless SEDUSA OTT, including its
                  parent, subsidiaries, affiliates, partners, officers,
                  directors, agents, and employees, from any claims,
                  liabilities, damages, losses, costs, and expenses, including
                  legal fees, arising from use of the platform, content
                  submissions, or violation of these terms or the rights of
                  others. SEDUSA OTT reserves the right to assume control of the
                  defense against such claims, and you agree to cooperate. This
                  provision remains effective even after the termination of your
                  platform use. User agree to use SEDUSA OTT service, website,
                  and application is at their own risk. The service and access
                  to optional tools are provided 'as is' and 'as available,'
                  without any warranties or conditions, whether express or
                  implied. SEDUSA OTT and its affiliates do not guarantee the
                  accuracy or completeness of content available on the
                  website/application or linked resources, and disclaim all
                  liability for errors or inaccuracies. We are not liable for
                  any damages, whether direct, indirect, incidental, punitive,
                  or consequential, arising from your use of the service or
                  packages, including any errors in content or loss of data. Our
                  liability is limited to the maximum extent permitted by law,
                  and we do not guarantee uninterrupted, secure, or error-free
                  service. The use of optional third-party tools is at your own
                  risk, and we bear no liability related to such tools. These
                  terms do not limit any non-waivable consumer rights under
                  various country's laws.
                </p>
                <br />
                <h5>
                  <b>8. Modification of Terms</b>{" "}
                </h5>{" "}
                <br />
                <p>
                  Terms Update: SEDUSA OTT reserves the right to modify or
                  update these Terms and Conditions at any time. Users will be
                  notified of such changes, and continued use of the platform
                  constitutes acceptance of the modified terms.
                </p>
                <br />
                <h5>
                  <b>9. Governing Law and Dispute Resolution</b>
                </h5>{" "}
                <br />
                <p>
                  Jurisdiction: These Terms and Conditions shall be governed by
                  and construed in accordance with the laws of India and any
                  disputes will be subject to the exclusive jurisdiction of the
                  courts in THRISSUR. By creating an account on SEDUSA OTT, you
                  acknowledge and agree to be bound by these Terms and
                  Conditions. If you have any questions or concerns regarding
                  these terms, please contact us at <i>sedusaott@gmail.com</i>
                  Last Updated: Jan 14 2024
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default TermsAndConditions;
