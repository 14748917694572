import axios from "../network/axiosConfig";

export const sedusaSignUp = async (userData) => {
  try {
    const response = await axios.post("/public/auth/signup", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sedusaSignIn = async (userData) => {
  try {
    const response = await axios.post("/public/auth/login", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sedusaVerify = async (userData) => {
  try {
    const response = await axios.post("/app/verify", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sedusaSubscriptions = async () => {
  try {
    const response = await axios.get(`/app/subscription/plan`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const list = async () => {
  try {
    const response = await axios.get("/app/subscription/plan");
    return response.data; // You might return additional data if needed
  } catch (error) {
    // Handle login error
    throw error;
  }
};

export const checkout = async (subscriptionPlanId) => {
  try {
    let body = { subscriptionPlanId };
    const response = await axios.post("/app/payment/checkout", body);
    return response.data; // You might return additional data if needed
  } catch (error) {
    // Handle login error
    throw error;
  }
};

const home = async () => {
  try {
    const response = await axios.get("/app/home/list");
    return response.data; // You might return additional data if needed
  } catch (error) {
    // Handle login error
    throw error;
  }
};

const banners = async () => {
  try {
    const response = await axios.get("/app/banner/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const showDetails = async (id) => {
  try {
    const response = await axios.get(`/app/show/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const videoDetails = async (id) => {
  try {
    const response = await axios.get(`/app/video/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const categoryDetails = async (id) => {
  try {
    const response = await axios.get(`/app/category/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const seasonDetails = async (id) => {
  try {
    const response = await axios.get(`/app/show-season/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProfile = async (data) => {
  try {
    const response = await axios.post("/app/profile/update", data);
    return response.data; // You might return additional data if needed
  } catch (error) {
    // Handle login error
    throw error;
  }
};
const search = async (title) => {
  try {
    const response = await axios.get(`/app/home/search?title=${title}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const similarVideos = async (queryString) => {
  try {
    const response = await axios.get(`/app/suggestions${queryString}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const fetchProfile = async (queryString) => {
  try {
    const response = await axios.get(`/app/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const sendContactUs = async (data) => {
  try {
    const response = await axios.post(`/public/contact`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getRefreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw Error("no token found");
  }
  const response = await axios.post("/public/auth/token", {
    refreshToken,
  });
  return response.data;
};

const getMediaFileDetails = async (videoId) => {
  const response = await axios.post("/app/stream/player-files", {
    videoId,
  });
  return response.data;
};

const publishEvent = async (data) => {
  const {
    type,
    horizondalImage,
    totalDurationPlayed,
    verticalImage,
    videoId,
    videoName,
  } = data;
  const response = await axios.post("/app/publish-event", data);
  return response.data;
};

// /app/home/search?title=""
export default {
  list,
  home,
  banners,
  showDetails,
  videoDetails,
  categoryDetails,
  seasonDetails,
  updateProfile,
  search,
  similarVideos,
  fetchProfile,
  sendContactUs,
  getRefreshToken,
  getMediaFileDetails,
  publishEvent,
};
