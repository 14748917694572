import React, { useEffect, useState } from "react";
import "../style/subscription.css";
import { checkout, sedusaSubscriptions } from "../network/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Loader from "../utils/Loader";

function Subscriptions() {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  useEffect(() => {
    fetchSubscriptionList();
  }, []);

  const fetchSubscriptionList = async () => {
    try {
      setIsLoading(true);
      let subscriptionResponse = await sedusaSubscriptions();
      setSubscriptions(subscriptionResponse.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {}
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSubscriptionList();
    // setIsLoading(false);
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleSubscribe = async (subscriptionId) => {
    try {
      setIsLoading(true);

      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      let subscriptionResponse = await checkout(subscriptionId);
      let options = subscriptionResponse.data;
      setIsLoading(false);
      options = {
        ...options,
        handler: function (response) {
          // Callback function for success
          toast.success("Payment Successful");
          localStorage.setItem("isSubscribedUser", true);
          setTimeout(() => {
            window.location.href = "/";
            // navigate("/"); // Navigate to the home page after sign-in}
          }, 1000);
        },
        // Add error callback
        error: function (error) {
          // Callback function for errors
          toast.error("Payment Error");
        },
      };

      const rzpay = new window.Razorpay(options); // Use window.Razorpay here
      rzpay.open();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <div className="sedusa-subscription-wrapper">
          <div className="sedusa-subscription-header">
            <span className="sedusa-subscription-title">Subscription plan</span>
          </div>
          <div className="sedusa-subscription-body">
            {subscriptions.map((subscription, index) => {
              return (
                <>
                  <div className="sedusa-subscription-card">
                    <span className="sedusa-subscription-card-header">
                      {subscription.name}
                    </span>
                    <span className="sedusa-subscription-card-price">
                      <span className="sedusa-subscription-card-rupay">₹</span>
                      {subscription.offerPrice && subscription.offerPrice > 0
                        ? `${subscription.offerPrice}/ `
                        : ""}
                      <span
                        style={{
                          textDecoration:
                            subscription.offerPrice &&
                            subscription.offerPrice > 0
                              ? "line-through"
                              : "none",
                        }}
                      >
                        {subscription.price}
                      </span>
                    </span>
                    <span className="sedusa-subscription-card-underline"></span>
                    <span className="sedusa-subscription-card-duration">
                      {subscription.subscriptionPlanType}
                    </span>
                    <span className="sedusa-subscription-card-button">
                      {" "}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubscribe(subscription?.id);
                        }}
                      >
                        SELECT PLAN
                      </button>
                    </span>
                  </div>
                </>
              );
            })}
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default Subscriptions;
