import React, { useEffect, useState } from "react";
import videojs from "video.js";
import "@hisolver/videojs-youtube";
import "video.js/dist/video-js.css";
import "videojs-seek-buttons";
import "videojs-hotkeys";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";

/// import "@videojs/themes/dist/fantasy/index.css";
import { getMimeType, handleVideoPlayerEvent } from "./VideoJsHelper";
import service from "../network/service";

export const VideoJS = ({
  url,
  poster,
  id,
  verticalImage,
  name,
  resumeSecond,
}) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [source, setSource] = useState([]);

  console.log("resumeSecond", resumeSecond);
  let videoDetails = {
    videoId: id,
    horizondalImage: poster,
    verticalImage,
    videoName: name,
  };

  const getPlayerFileDetails = async (videoId) => {
    service
      .getMediaFileDetails(videoId)
      .then((response) => {
        setSource([
          {
            src: response["master"],
            type: "application/vnd.apple.mpegurl",
          },
        ]);
      })
      .catch(() => {
        if (url) {
          !source.length &&
            setSource([
              {
                src: url,
                type: getMimeType(url),
              },
            ]);
        }
      });
  };

  useEffect(() => {
    if (id) {
      getPlayerFileDetails(id);
    } else {
      setSource([
        {
          src: url,
          type: getMimeType(url),
        },
      ]);
    }
  }, [id]);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: "16:9",
    controlBar: {
      fullscreenToggle: true, // Enable full-screen button
    },
    plugins: {
      hotkeys: {
        volumeStep: 0.1,
        seekStep: 5,
        enableModifiersForNumbers: false,
      },
    },
    poster,
  };

  const handlePlayerReady = async (player, videoDetails) => {
    playerRef.current = player;

    player.hlsQualitySelector({
      displayCurrentQuality: true,
    });

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
    await handleVideoPlayerEvent(player, videoDetails);
  };

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoElement.classList.add("vjs-default-skin");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        videoJsOptions,
        () => {
          videojs.log("player is ready");
          videoElement.focus();
          handlePlayerReady && handlePlayerReady(player, videoDetails);
          resumeSecond && player.currentTime(resumeSecond);
        }
      ));
    } else {
      const player = playerRef.current;
      player.autoplay(videoJsOptions.autoplay);
      player.plugin = {
        hotkeys: {
          volumeStep: 0.1,
          seekStep: 5,
          enableModifiersForNumbers: false,
        },
      };

      resumeSecond && player.currentTime(resumeSecond);
    }
  }, [videoJsOptions, videoRef]);

  useEffect(() => {
    console.log(source);
    const player = playerRef.current;
    if (player && source.length) {
      player.src(source);
    }
  }, [source]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
