import axios from "axios";
import networkService from "./service";

//axiosRetry(axios, { retries: 3 });
const apiBaseUrl = process.env.REACT_APP_API_URL;
const channelId = process.env.REACT_APP_CHANNEL_ID;
const publisherlId = process.env.REACT_APP_PUB_ID;
try {
  // Set up request interceptor
  axios.interceptors.request.use(
    (config) => {
      config.url = `${apiBaseUrl}${config.url}`;

      // Check if the request needs authentication
      // const isApiRequest = config.url.startsWith('/api'); // Adjust this based on your API structure

      // if (isApiRequest) {
      // Add the access token to the headers
      const accessToken = localStorage.getItem("accessToken"); // Assuming you store the token in localStorage
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers["pubid"] = publisherlId;
        config.headers["chid"] = channelId;
        config.headers["channel-id"] = channelId;
      } else {
        config.headers["chid"] = channelId;
        config.headers["channel-id"] = channelId;
        config.headers["pubid"] = publisherlId;

        // Handle the case where there is no access token (e.g., redirect to login)
        // You might also refresh the token here if using refresh tokens
      }
      // }
      return config;
    },
    (error) => {
      //console.log("error", error);
      // Handle request error
      return Promise.reject(error);
    }
  );

  // Set up response interceptor
  axios.interceptors.response.use(
    (response) => {
      //console.log("response", response);
      // Modify the response data if needed
      return response;
    },
    async (error) => {
      if (error.response.status == 401 || error.response.status == 403) {
        try {
          const originalRequest = error.config;
          const refreshTokenResponse = await networkService.getRefreshToken();
          const { refresh_token, access_token } = refreshTokenResponse?.data;
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          // window.location.href = "#";
          originalRequest.url = originalRequest.url.replace(
            `${apiBaseUrl}`,
            ""
          );
          console.log(originalRequest);
          return axios(originalRequest);
        } catch (error) {
          localStorage.clear();
          window.location.href = "/";
        }
      } else {
        return Promise.reject(error);
      }
      // Handle response error
    }
  );
} catch (error) {
  //console.log("ERTRR", error);
}
export default axios;
