import React from "react";
import Jumbotron from "./Jumbotron";

export default function ComingSoon() {
  let item = {
    id: 1,
    title: "Launching soon.",
    subTitle:
      "Watch on smart TVs, PlayStation, Xbox, Chromecast, Apple TV, Blu-ray players and more.",
    image: "/images/home-tv2.png",
    alt: "Movie Carousel",
    direction: "row",
  };
  return (
    <Jumbotron.Container>
      <Jumbotron key={item.id} direction={item.direction}>
        <Jumbotron.Pane>
          <Jumbotron.Title>{item.title}</Jumbotron.Title>
          <Jumbotron.SubTitle>{item.subTitle}</Jumbotron.SubTitle>
        </Jumbotron.Pane>
        <Jumbotron.Pane>
          <Jumbotron.Image src={item.image} alt={item.alt} />
        </Jumbotron.Pane>
      </Jumbotron>
    </Jumbotron.Container>
  );
}
