import React, { useEffect, useState } from "react";
import "../style/mainStyle.css";
import "../style/VideoPlayer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlay } from "@fortawesome/free-solid-svg-icons";
import {
  faEye,
  faCalendar,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import Loader from "../utils/Loader";
import TrailerPlayer from "../video/TrailerPlayer";
import service from "../network/service";
import CategorySlider from "../reusable/CategorySlider";

function VideoDetails({ id, data, type, resumeTime }) {
  const [showTrailer, setShowTrailer] = useState(false);
  const [loader, setLoader] = useState(true);
  const [castNCrew, setCAstNCrew] = useState([]);
  const [similarVideos, setSimilarVideos] = useState([]);
  useEffect(() => {
    setLoader(true);
    let queryString = `?itemId=${id}`;
    if (data?.category?.length > 0) {
      queryString += `&`;
      data.category.map((item, index) => {
        if (index > 0) {
          queryString += "&";
        }
        queryString += `catId[${index}]=${item.id}`;
      });
    }
    // you may also like
    service
      .similarVideos(queryString)
      .then((data) => {
        data.data.map((item) => {
          item.imageSrc = item.verticalImage;
          item.title = item.itemName;
          // banner.linkTo=`/movie/${banner?.itemId}`;
          item.linkTo =
            item.itemType == "SHOW"
              ? `/show/details/${encodeURIComponent(item?.itemId)}`
              : `/movie/details/${encodeURIComponent(item?.itemId)}`;
        });
        setSimilarVideos(data.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [id]);
  useEffect(() => {
    if (data?.castAndCrew) {
      // Function to group data by role
      const groupDataByRole = (data) => {
        const groupedData = {};

        data.forEach((item) => {
          const { role } = item;

          // If the role is not a key in the groupedData object, create an empty array for it
          if (!groupedData[role]) {
            groupedData[role] = [];
          }

          // Push the item to the array for that role
          groupedData[role].push(item);
        });

        return groupedData;
      };

      // Example usage
      const groupedData = groupDataByRole(data?.castAndCrew);
      setCAstNCrew(groupedData);
      data.castNcrew = groupedData;
    }
  }, [data, id]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <>
            {" "}
            <div className="page-content-area vfx-item-ptb pt-3">
              <div className="container-fluid">
                {showTrailer && (
                  <div className="sedusa-overlay">
                    <div className="sedusa-overlay-content">
                      <div className="sedusa-modal-title">
                        <span>Trailer</span>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowTrailer(false);
                          }}
                          className="sedusa-trailer-close"
                        >
                          <FontAwesomeIcon icon={faClose} />
                        </button>
                      </div>
                      <div>
                        <TrailerPlayer
                          trailerURL={data?.trailerURL}
                          image={data?.thumbnail_horizontal}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 mb-4">
                    <div className="detail-poster-area">
                      {!data?.showVideo && (
                        <div className="play-icon-item">
                          <Link
                            to={`/movie/watch/${id}${
                              resumeTime ? `?watch=${resumeTime}` : ""
                            }`}
                            state={{ data: data, id: id, type: "VIDEO" }}
                            className="icon"
                          >
                            <FontAwesomeIcon icon={faPlay} />
                            <span className="ripple"></span>
                          </Link>
                        </div>
                      )}
                      <div className="video-post-date">
                        {/* <span className="video-posts-author">
                          <FontAwesomeIcon icon={faEye} />
                          108 Views
                        </span> */}
                        {data?.dateOfRelease && (
                          <span className="video-posts-author">
                            <FontAwesomeIcon icon={faCalendar} />
                            {`   ${data?.dateOfRelease}`}
                          </span>
                        )}
                        <div className="video-watch-share-item"></div>
                      </div>

                      <div className="dtl-poster-img">
                        <img
                          src={data?.thumbnail_horizontal}
                          alt={data.title}
                          title={data?.title}
                          style={{
                            minHeight: "300px",
                            minWidth: "200px",
                            maxHeight: "400px",
                            objectFit: "fill",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 mb-4">
                    <div className="poster-dtl-item">
                      <h2>
                        <a href="" title="Romancham">
                          {data?.title}
                        </a>
                      </h2>
                      <ul className="dtl-list-link">
                        {data?.category?.map((item) => {
                          //console.log("CTA", item);
                          return (
                            <li>
                              <Link
                                to={`/category/${item.id}`}
                                title="Malayalam"
                                state={{ name: item.name }}
                              >
                                {item.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="video-watch-share-item mb-3">
                        {data?.trailerURL && (
                          <div
                            className="subscribe-btn-item"
                            style={{ marginLeft: "0px !important" }}
                          >
                            <a
                              onClick={() => {
                                setShowTrailer(true);
                              }}
                              style={{ cursor: "pointer" }}
                              title="Watch Trailer"
                            >
                              <FontAwesomeIcon icon={faPlayCircle} />
                              Watch Trailer
                            </a>
                          </div>
                        )}
                      </div>
                      <div>
                        {Object.keys(castNCrew).map((role) => (
                          <span key={role} className="des-bold-text">
                            <strong>{role}s:</strong>
                            {castNCrew[role]?.length > 0 &&
                              castNCrew[role].map((item, index) => (
                                <a key={index} href="" title={`${role}s`}>
                                  {index === 0
                                    ? ` ${item.name}`
                                    : `, ${item.name}`}
                                </a>
                              ))}
                          </span>
                        ))}
                      </div>
                      <h3>{data?.description}</h3>
                    </div>
                  </div>
                </div>
                {!data.showVideo && similarVideos?.length > 0 && (
                  <div className="row">
                    <div className="video-carousel-area vfx-item-ptb related-video-item">
                      <div className="container-fluid ">
                        <div className="row" style={{ padding: "inherit" }}>
                          <div className="col-md-12 p-0">
                            <div className="vfx-item-section">
                              <h3>You May Also Like</h3>
                            </div>
                            <div className="video-carousel owl-carousel">
                              {/* <TopSearches /> */}
                              <CategorySlider slides={similarVideos} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data?.showVideo && (
                  <div className="row">
                    <div className="video-shows-section vfx-item-ptb tv-season-related-block">
                      <div className="container-fluid">
                        <div className="row" style={{ padding: "inherit" }}>
                          <div className="col-md-12 p-0">
                            <div className="vfx-item-section">
                              <h3>Episodes</h3>
                            </div>
                            <div
                              className="tv-season-video-carousel owl-carousel"
                              style={{ display: "flex" }}
                            >
                              {data?.showVideo?.map((item, index) => {
                                //console.log("item", item);
                                return (
                                  <div
                                    className="single-video show-details-single-video"
                                    style={{ marginRight: "10px" }}
                                  >
                                    <Link
                                      // to={`/movie/watch/${id}`}
                                      state={{
                                        data: item.video,
                                        id: id,
                                        type: "VIDEO",
                                      }}
                                      to={`/movie/details/${item?.video?.id}`}
                                      // state={{ type: "SEASON" }}
                                      //   href="https://demoo.sedusa.in/shows/ms-marvel/seasons/season-1/17"
                                      title="Season 1"
                                    >
                                      <div className="video-img">
                                        <img
                                          src={item.video.thumbnail_horizontal}
                                          alt="Season 1"
                                          //   alt="Season 1" title="Season 1"
                                        />
                                      </div>
                                      <div className="season-title-item">
                                        <h3>{item.video?.title}</h3>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>{" "}
          </>
        </>
      )}
    </>
  );
}

export default VideoDetails;
