import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@splidejs/splide/css";
import "../style/responsive.css";
import "../style/megaMenu.css";
import "../style/mainStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSearch,
  faBars,
  faChevronDown,
  faUser,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SearchComponent from "../reusable/SearchComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "@splidejs/splide/css";
import "../style/responsive.css";
import "../style/megaMenu.css";
import "../style/mainStyle.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "../style/UpcomingSplider.css";
import "../style/search.css";
import service from "../network/service";

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [showList, setShowList] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const isVerified = localStorage.getItem("isVerified") == "true";
  // console.log("isVerified", isVerified);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopup, setShowPopUp] = useState(false);

  const menuRef = useRef(null);
  let user = localStorage.getItem("user");
  let userDetails = JSON.parse(user);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const logOut = () => {
    localStorage.clear();
    navigate("/signin");
  };
  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Clicked outside the menu, close the list
        setShowList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [filteredMovies, setFilteredMovies] = useState([]);

  const handleSearch = (searchQuery) => {
    if (searchQuery == "") {
      setFilteredMovies([]);
    } else {
      service.search(searchQuery).then((data) => {
        data.data.map((item) => {
          //    console.log("item", item);
          // item.imgSRc = item.verticalImage;
          item.imageSrc = item.verticalImage;
          item.title = item.itemName;
          // banner.linkTo=`/movie/${banner?.itemId}`;
          item.linkTo =
            item.itemType == "SHOW"
              ? `/show/details/${encodeURIComponent(item?.itemId)}`
              : `/movie/details/${encodeURIComponent(item?.itemId)}`;
        });
        //console.log("data", data.data);

        setFilteredMovies(data.data);
      });
    }
  };

  return (
    <header>
      <div
        className="main-menu"
        style={{
          width: "100%",
          position: "fixed",
          zIndex: 100,
        }}
      >
        <nav className="header-section pin-style">
          <div className="container-fluid">
            <div className="mod-menu">
              <div className="row">
                <div className="col-2">
                  <a href="/" title="logo" className="logo">
                    <img src="/images/180 X 34.png" alt="logo" title="logo" />
                  </a>
                </div>
                {/* {isLoggedIn && (
                  <div className="col-7 nav-order-last nopadding">
                    <div className="main-nav leftnav">
                      <ul className="top-nav">
                        <li className="visible-this d-md-none menu-icon">
                          {" "}
                          <a
                            href="#"
                            // className="navbar-toggle collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#menu"
                            aria-expanded="false"
                            title="menu-toggle"
                            className={`navbar-toggle  ${
                              isMenuOpen ? "collapsed" : ""
                            }`}
                            onClick={toggleMenu}
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </a>{" "}
                        </li>
                      </ul>
                      {/* <div
                        id="menu"
                        className={`header-menu ${
                          isMenuOpen ? "collapse" : ""
                        }`}
                      >
                        <ul className="nav vfx-item-nav">
                          <li>
                            <a href="/" className=" active" title="home">
                              Home
                            </a>
                          </li>
                          <li>
                            <a href="" className="" title="Movies">
                              Movies
                            </a>
                          </li>
                          <li>
                            <a href="" className="" title="Web Series">
                              Web Series
                            </a>
                          </li>
                          <li>
                            <a href="/" className="" title="Sports">
                              Sports
                            </a>{" "}
                            <span className="arrow"></span>
                            <ul className="dm-align-2 mega-list">
                              <li>
                                <a href="" title="Athletics">
                                  Athletics
                                </a>
                              </li>
                              <li>
                                <a href="" title="Athletics">
                                  Athletics
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div> */}
                {/* </div>
                  </div>
                )} */}
                {isLoggedIn && isVerified && (
                  <div className="col-3">
                    <div className="right-sub-item-area">
                      <div className="search-item-block">
                        <form className="navbar-form navbar-left">
                          <a
                            type="submit"
                            href="#popup1"
                            className="btn btn-default open"
                            title="search"
                          >
                            <FontAwesomeIcon
                              icon={faSearch}
                              onClick={() => {
                                setShowPopUp(true);
                              }}
                            />
                          </a>
                        </form>
                      </div>
                      <div className="subscribe-btn-item">
                        {/* <a href="" title="subscribe">
                        <img
                          src="/images/ic-subscribe.png"
                          alt="ic-subscribe"
                          title="ic-subscribe"
                        />
                      </a> */}

                        <Link to={`/subscriptions`}>
                          <img
                            src="/images/ic-subscribe.png"
                            alt="ic-subscribe"
                            title="ic-subscribe"
                          />{" "}
                        </Link>
                      </div>
                      <div className="user-menu" ref={menuRef}>
                        <div
                          className="user-name"
                          onClick={() => {
                            setShowList(!showList);
                          }}
                        >
                          <span>
                            <img
                              src="/images/user-avatar.png"
                              alt="profile_img"
                              title=""
                              id="userPic"
                            />
                          </span>
                          {/* <FontAwesomeIcon icon={faChevronDown} /> */}
                        </div>

                        {showList && (
                          <ul
                            className="content-user"
                            style={{
                              visibility: "visible",
                              opacity: "1",
                            }}
                          >
                            <li key="email">
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: "11px",
                                  paddingTop: "3px",
                                  margin: "0px",
                                  textAlign: "center",
                                  fontWeight: 800,
                                }}
                              >
                                {JSON.parse(localStorage.getItem("user")).email}
                              </p>
                            </li>
                            <li key="dashboard">
                              <Link to={`/dashboard`}>
                                <FontAwesomeIcon
                                  icon={faDatabase}
                                  style={{ marginRight: "5px" }}
                                />
                                Dashboard
                              </Link>
                            </li>
                            {/* <li>
                          <Link to={`/subscriptions`}>
<FontAwesomeIcon icon={faDatabase} style={{"marginRight":"5px"}}/> */}
                            {/* <a href="/subscriptions" title="Dashboard"><i class="fa fa-database"></i> */}
                            {/* Dashboard
                            </Link> */}
                            {/* </a> */}
                            {/* </li> */}
                            <li key="profile">
                              <Link to={`/profile`}>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  style={{ marginRight: "5px" }}
                                />
                                Profile
                              </Link>
                            </li>
                            {/* <li><a href="/" title="My Watchlist"><i class="fa fa-list"></i>My Watchlist</a></li> */}
                            <li>
                              <i class="fa fa-list"></i>
                            </li>

                            <li key="logout">
                              <a href="/" onClick={logOut} title="Logout">
                                <i className="fa fa-sign-out-alt"></i>Logout
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                      {/* <div className="signup-btn-item">
                      <a onClick={logOut} title="login">
                        <img
                          src="/images/ic-signup-user.png"
                          alt="ic-signup-user"
                          title="signup-user"
                        />
                        <span>Logout</span>
                      </a>
                    </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* {setShowPopUp && ( */}
      <div id="popup1" className="popup-view popup-overlay">
        <div className="search">
          <div className="search-container has-results">
            <span className="title">Search</span>
            <SearchComponent
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
          <div className="search-results mt-4" id="search_output">
            {filteredMovies?.length > 0 ? (
              <div className="sedusa-search-wrapper">
                <span className="sedusa-search-title"></span>
                {filteredMovies.map((movie) => (
                  <div className="sedusa-search-main-div">
                    <div className="sedusa-search-sub-div">
                      <Link
                        to={movie.linkTo}
                        onClick={() => {
                          let popupDoc = document.getElementById("popup");
                          popupDoc.style.display = "none";
                        }}
                      >
                        <span className="sedusa-search-tile"></span>
                        <img
                          className="sedusa-search-image"
                          src={movie.imageSrc}
                        ></img>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No results found</p>
            )}
          </div>
        </div>
        <a className="close" href="#" title="close">
          <FontAwesomeIcon icon={faTimes} />
        </a>
      </div>
      {/* )} */}
    </header>
  );
}

export default Header;
