import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import PageNotFoundImage from "";

const PageNotFound = () => {
  return (
    <div id="main-wrapper">
      <div className="container-fluid px-0 m-0 mx-auto">
        <div className="row  overflow-hidden">
          <div
            className="col-md-12 h-100 w-100 justify-center flex "
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "8rem 0rem",
            }}
          >
            <img src={"/404.png"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
