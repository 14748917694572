import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style/signIn.css";
import { sedusaSignUp } from "../network/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../footer/Footer";

const SignUp = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password_confirmation: "",
    mobile: "",
    couponCode: "",
  });
  const [submit, setSubmit] = useState(false);
  // Validation state
  const [validationError, setValidationError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password_confirmation: "",
    mobile: "",
    couponCode: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = async () => {
    let isValid = true;
    let errors = {};
    // Validate First Name
    if (formData.firstName.trim() === "") {
      errors.firstName = "First Name is required.";
      isValid = false;
    } else {
      errors.firstName = "";
    }

    // Validate Last Name
    if (formData.lastName.trim() === "") {
      errors.lastName = "Last Name is required.";
      isValid = false;
    } else {
      errors.lastName = "";
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email address.";
      isValid = false;
    } else {
      errors.email = "";
    }

    // Validate Password
    if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters.";
      isValid = false;
    } else {
      errors.password = "";
    }

    // Validate Password Confirmation
    if (formData.password !== formData.password_confirmation) {
      errors.password_confirmation = "Passwords do not match.";
      isValid = false;
    } else {
      errors.password_confirmation = "";
    }

    // Validate Mobile Number
    const mobileRegex = /^\d{10}$/; // Assumes a 10-digit phone number
    if (!mobileRegex.test(formData.mobile)) {
      errors.mobile = "Invalid mobile number.";
      isValid = false;
    } else {
      errors.mobile = "";
    }
    setValidationError({ ...errors });
    return isValid;
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      localStorage.removeItem("isLoggedIn");
      validateForm()
        .then(async (valid) => {
          if (valid) {
            setSubmit(true);
            let signUpData = {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              password: formData.password,
              mobile: formData.mobile,
              channelId: process.env.REACT_APP_CHANNEL_ID || "",
              publisherId: process.env.REACT_APP_PUB_ID || "",
            };
            if (formData.couponCode && formData.couponCode.length > 0) {
              signUpData.couponCode = formData.couponCode;
            }
            // Make the API call to signup
            const result = await sedusaSignUp(signUpData);
            // Show success toast
            toast.success("Email verification successful!", {
              position: toast.POSITION.TOP_CENTER,
            });

            // Reset the form data
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              password_confirmation: "",
              mobile: "",
            });
            localStorage.setItem("isLoggedIn", "TRUE");
            localStorage.setItem("accessToken", result.data.access_token);
            localStorage.setItem("refreshToken", result.data.refresh_token);
            localStorage.setItem(
              "isSubscribedUser",
              result.data.current_subscription?.id ?? false
            );
            localStorage.setItem("user", JSON.stringify(result.data.user));

            // setTimeout(() => {
            setSubmit(false);
            window.location.href = "/validateOTP";

            // navigate("/validateOTP"); // Navigate to the home page after sign-in}
          } else {
            setSubmit(false);
            toast.error("Please fill mandatory fields");
          }
        })
        .catch((error) => {
          setSubmit(false);

          toast.error(error.response?.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (error) {
      // Handle signup error
      // Show error toast
      setSubmit(false);

      toast.error(error.response?.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div id="main-wrapper">
      <div className="container-fluid px-0 m-0 h-100 mx-auto">
        <div className="row g-0 min-vh-100 overflow-hidden">
          <div className="col-md-12">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="hero-mask"></div>
              <div
                className="hero-bg hero-bg-scroll"
                style={{
                  backgroundImage: `url(/images/login-signup-bg-img.jpg)`,
                }}
              ></div>
              <div className="hero-content mx-auto w-100 h-100 d-flex flex-column justify-content-center">
                {/* <div className="row">
                  <div className="col-12 col-lg-5 col-xl-5 mx-auto">
                    <div className="logo mt-40 mb-20 justify-content-center d-flex text-center">
                      <a href="/" title="logo">
                        <img
                          src="/images/180 X 34.png"
                          alt="logo"
                          title="logo"
                          className="login-signup-logo"
                        />
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4 col-md-6 col-sm-6 mx-auto d-flex align-items-center login-item-block">
                  <div className="container login-part">
                    <div className="row">
                      <div className="col-12 col-lg-12 col-xl-12 mx-auto">
                        <h2
                          className="form-title-item mb-4"
                          style={{ color: "white" }}
                        >
                          Sign up
                        </h2>
                        <form id="signupform" role="form">
                          <div className="message"></div>
                          <div className="signup-name-wrapper">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                id="firstName"
                                value={formData.firstName}
                                placeholder="First Name"
                                onChange={handleInputChange}
                              />
                              {validationError.firstName?.length > 0 && (
                                <p className="text-danger">
                                  {validationError.firstName}
                                </p>
                              )}
                            </div>
                            <></>
                            <div className="form-group signupLastName">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                id="lastName"
                                value={formData.lastName}
                                placeholder="Last Name"
                                onChange={handleInputChange}
                              />
                              {validationError.lastName?.length > 0 && (
                                <p className="text-danger">
                                  {validationError.lastName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              value={formData.email}
                              placeholder="Email"
                              onChange={handleInputChange}
                            />
                            {validationError.email?.length > 0 && (
                              <p className="text-danger">
                                {validationError.email}
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                              value={formData.password}
                              placeholder="Password (At least 8 characters)"
                              onChange={handleInputChange}
                            />
                            {validationError.password?.length > 0 && (
                              <p className="text-danger">
                                {validationError.password}
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              id="password_confirmation"
                              name="password_confirmation"
                              value={formData.password_confirmation}
                              placeholder="Confirm Password"
                              onChange={handleInputChange}
                            />
                            {validationError.password_confirmation?.length >
                              0 && (
                              <p className="text-danger">
                                {validationError.password_confirmation}
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="mobile"
                              name="mobile"
                              value={formData.mobile}
                              placeholder="Mobile"
                              onChange={handleInputChange}
                            />
                            {validationError.mobile?.length > 0 && (
                              <p className="text-danger">
                                {validationError.mobile}
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="couponCode"
                              id="couponCode"
                              value={formData.couponCode}
                              placeholder="I have a coupon"
                              onChange={handleInputChange}
                            />
                            {validationError.couponCode?.length > 0 && (
                              <p className="text-danger">
                                {validationError.couponCode}
                              </p>
                            )}
                          </div>
                          <button
                            className="btn-submit btn-block mb-4"
                            type="submit"
                            disabled={submit}
                            onClick={handleSignup}
                          >
                            {!submit ? "Sign up" : "Processing"}
                          </button>
                        </form>
                        <p className="text-3 text-center mb-3">
                          Already Sign Up?{" "}
                          <Link
                            className="btn-link"
                            title="signup"
                            to="/signin"
                          >
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          width: "-webkit-fill-available",
        }}
      >
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
